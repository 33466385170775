import clsx from 'clsx';
import React, { memo } from 'react';
import isArray from 'lodash/isArray';

import ErrorsTextTextLine, {
  ErrorsTextErrorTypeSupported,
} from './ErrorsTextTextLine';

import './style.scss';

export type ErrorsTextProps = {
  errorsListOrErrorText:
    | ErrorsTextErrorTypeSupported
    | ErrorsTextErrorTypeSupported[];
  className?: string;
};

const ErrorsText: React.FC<ErrorsTextProps> = ({
  errorsListOrErrorText,
  className,
}) => {
  if (!errorsListOrErrorText) {
    return null;
  }

  const errorsList = (
    isArray(errorsListOrErrorText)
      ? errorsListOrErrorText
      : [errorsListOrErrorText]
  ) as ErrorsTextErrorTypeSupported[];

  return (
    <div
      className={clsx('errors_text', { error: errorsList.length }, className)}
    >
      {errorsList?.length > 1 ? (
        <ul className="errors_text__bullet_list">
          {errorsList.map((error, index) => (
            <li key={index}>
              <ErrorsTextTextLine error={error} />
            </li>
          ))}
        </ul>
      ) : (
        !!errorsList?.length && <ErrorsTextTextLine error={errorsList[0]} />
      )}
    </div>
  );
};

export default memo(ErrorsText);
