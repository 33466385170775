import { createSlice } from '@reduxjs/toolkit';

import { SboAsClient } from '_types/sbo.interface';

import { fetchClient } from 'store/actions/client';

interface ClientState {
  isLoading: boolean;
  data: SboAsClient | null;
}

export const clientSliceInitialState: ClientState = {
  isLoading: true,
  data: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState: clientSliceInitialState,
  reducers: {
    resetState: () => clientSliceInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClient.pending, (state) => {
      state.isLoading = true;
      state.data = null;
    });
    builder.addCase(fetchClient.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchClient.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { resetState } = clientSlice.actions;

export default clientSlice;
