import React, { memo } from 'react';

interface TwitterBlackIconProps {
  className?: string;
}
const TwitterBlackIcon: React.FC<TwitterBlackIconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM14.1458 6.92775C14.3025 10.3905 11.7203 14.2515 7.14825 14.2515C5.75775 14.2515 4.464 13.8443 3.37425 13.1453C4.68075 13.299 5.98425 12.9367 7.01925 12.126C5.9415 12.1057 5.0325 11.394 4.71975 10.416C5.106 10.4895 5.4855 10.4677 5.83125 10.374C4.647 10.1362 3.83025 9.06975 3.8565 7.929C4.188 8.1135 4.56825 8.2245 4.971 8.23725C3.87525 7.5045 3.56475 6.057 4.209 4.95C5.42325 6.4395 7.2375 7.41975 9.2835 7.5225C8.92425 5.98275 10.0935 4.5 11.6827 4.5C12.39 4.5 13.0305 4.7985 13.479 5.27775C14.04 5.1675 14.5673 4.96275 15.0435 4.68075C14.859 5.256 14.469 5.73825 13.9613 6.04275C14.4592 5.98275 14.934 5.85075 15.375 5.655C15.0457 6.147 14.628 6.5805 14.1458 6.92775Z"
      fill="#2B2B2B"
    />
  </svg>
);

export default memo(TwitterBlackIcon);
