import { createContext } from 'react';

import {
  FirebaseAuthContextData,
  defaultFirebaseAuthContextData,
} from './utils';

const FirebaseAuthDataContext = createContext<FirebaseAuthContextData>(
  defaultFirebaseAuthContextData,
);

export default FirebaseAuthDataContext;
