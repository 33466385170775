import { MailChimpTrackEventData } from '_types/mailChimp.interface';

import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const mailchimpService = {
  trackEvent: (data: MailChimpTrackEventData) =>
    axiosApiInstance
      .post(`${baseUrl}/api/v1/mailchimp/trackEvent`, data)
      .then(({ data }) => data),
};
export default mailchimpService;
