import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';

import sessionsService from '_services/sessionsService';

import CrossIcon from 'app/components/Icons/CrossIcon';
import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import Loader from 'app/components/Loader';
import CongratulationsIcon from 'app/components/Icons/CongratulationsIcon';
import Button from 'app/components/Button';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowRateSessionModal } from 'store/reducers/modal';
import { rateSessionSelector } from 'store/selectors/session';
import { fetchSessionById } from 'store/actions/sessions';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import InfoModal from '../InfoModal/InfoModal';

import ModalFooter from './ModalFooter';
import { rateSteps } from './config';
import StepRateSelectorComponent from './StepRateSelectorComponent';

import './style.scss';

const RateModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();

  const showRateSessionModal = useAppSelector(rateSessionSelector);

  const [step, setStep] = useState<number>(rateSteps.advisorPresence);
  const [rates, setRates] = useState<(string | number | boolean)[]>([]);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);

  const onClose = () => {
    dispatch(actionHandleShowRateSessionModal(null));
  };

  const completeRating = useCallback(async () => {
    const sessionsRate = {};
    Object.keys(rateSteps).forEach((key, index) => {
      sessionsRate[key] = rates[index];
    });

    try {
      await sessionsService.postSessionsRate(
        showRateSessionModal?.sessionId,
        sessionsRate,
      );
      dispatch(fetchSessionById(Number(showRateSessionModal?.sessionId)));
      setIsShowSuccessModal(true);
    } catch {
      // eslint-disable-next-line no-alert
      window.alert('Something went wrong please try again');
    }
  }, [rates, dispatch, showRateSessionModal]);

  const onNextClick = async () => {
    if (step === rateSteps.feedback) {
      completeRating();
    } else if (step === rateSteps.advisorPresence) {
      if (rates[step] === true) {
        setStep((prevState) => prevState + 1);
      } else {
        completeRating();
      }
    } else {
      setStep((prevState) => prevState + 1);
    }
  };

  const { ref } = useOnClickOutside(!isShowSuccessModal, onClose);

  if (!showRateSessionModal) {
    return <Loader />;
  }

  return (
    <>
      {!isShowSuccessModal ? (
        <div className="modal_wrapper">
          <div className={clsx('modal_block rate_modal')} ref={ref}>
            <AvatarCircle
              className="modal_header_user_avatar"
              firstName={showRateSessionModal?.advisorFirstName}
              lastName={showRateSessionModal?.advisorLastName}
              src={showRateSessionModal?.advisorPhoto}
              size={{ small: '48', large: '48' }}
            />
            <Button
              type="btn"
              btnLook="filled"
              onClick={onClose}
              size="medium"
              className="modal_close_button"
            >
              <CrossIcon />
            </Button>
            <div className="modal_body">
              <StepRateSelectorComponent
                setRate={setRates}
                step={step}
                rate={rates}
                advisor={{
                  firstName: showRateSessionModal?.advisorFirstName,
                  lastName: showRateSessionModal?.advisorLastName,
                }}
                totalSteps={rateSteps.feedback}
              />
            </div>
            <ModalFooter
              active={rates[step] !== undefined || step === rateSteps.feedback}
              step={step + 1}
              totalSteps={rateSteps.feedback + 1}
              onCancelClick={onClose}
              onNextClick={onNextClick}
            />
          </div>
        </div>
      ) : (
        <InfoModal
          titleIcon={<CongratulationsIcon className="icon" />}
          title={t('misc.thank_for_your_rating')}
          message={
            <p className="text">{t('misc.your_opinion_very_important')}</p>
          }
          onClose={onClose}
          footer={
            <Button
              type="btn"
              btnLook="filled"
              value={t('misc.okay')}
              onClick={onClose}
              size="large"
            />
          }
        />
      )}
    </>
  );
};

export default memo(RateModal);
