import React, { memo } from 'react';

interface InstagramIconProps {
  className?: string;
}
const InstagramIcon: React.FC<InstagramIconProps> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3107 7.31733C19.1867 7.26667 18.848 7.256 16 7.256C13.152 7.256 12.8147 7.26667 11.6907 7.31733C8.79733 7.44933 7.44933 8.82 7.31867 11.6893C7.26667 12.8147 7.25467 13.152 7.25467 16C7.25467 18.848 7.26667 19.1853 7.31733 20.3107C7.44933 23.1747 8.792 24.5507 11.6893 24.6827C12.8133 24.7333 13.152 24.7453 16 24.7453C18.8493 24.7453 19.1867 24.7347 20.3107 24.684C23.204 24.552 24.5507 23.1787 24.6827 20.312C24.7333 19.1867 24.744 18.8493 24.744 16.0013C24.744 13.1533 24.7333 12.816 24.6827 11.692C24.5507 8.82133 23.2013 7.44933 20.3107 7.31733V7.31733ZM16 21.4773C12.9747 21.4773 10.5227 19.0253 10.5227 16C10.5227 12.9747 12.976 10.5227 16 10.5227C19.024 10.5227 21.4773 12.9747 21.4773 16C21.4773 19.0253 19.0253 21.4773 16 21.4773ZM21.6947 11.5867C20.988 11.5867 20.4147 11.0133 20.4147 10.3067C20.4147 9.6 20.988 9.02667 21.6947 9.02667C22.4013 9.02667 22.9747 9.6 22.9747 10.3067C22.9747 11.0133 22.4013 11.5867 21.6947 11.5867ZM19.556 16C19.556 17.964 17.964 19.556 16 19.556C14.036 19.556 12.444 17.964 12.444 16C12.444 14.036 14.036 12.444 16 12.444C17.964 12.444 19.556 14.036 19.556 16ZM25.3333 0H6.66667C2.98533 0 0 2.98533 0 6.66667V25.3333C0 29.0147 2.98533 32 6.66667 32H25.3333C29.016 32 32 29.0147 32 25.3333V6.66667C32 2.98533 29.016 0 25.3333 0V0ZM26.6027 20.3973C26.4267 24.276 24.268 26.4253 20.3987 26.6027C19.26 26.6547 18.8973 26.6667 16 26.6667C13.1027 26.6667 12.7413 26.6547 11.6027 26.6027C7.72533 26.4253 5.576 24.272 5.39733 20.3973C5.34533 19.26 5.33333 18.8973 5.33333 16C5.33333 13.104 5.34533 12.74 5.39733 11.6027C5.576 7.72533 7.728 5.57467 11.6027 5.39867C12.7413 5.34533 13.1027 5.33333 16 5.33333C18.8973 5.33333 19.26 5.34533 20.3987 5.39733C24.276 5.57467 26.4293 7.732 26.6027 11.6013C26.6547 12.74 26.6667 13.104 26.6667 16C26.6667 18.8973 26.6547 19.26 26.6027 20.3973V20.3973Z"
      fill="url(#paint0_radial_13868_12944)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_13868_12944"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.50004 34.4647) rotate(-90) scale(31.7144)"
      >
        <stop stopColor="#FFDD55" />
        <stop offset="0.1" stopColor="#FFDD55" />
        <stop offset="0.5" stopColor="#FF543E" />
        <stop offset="1" stopColor="#C837AB" />
      </radialGradient>
    </defs>
  </svg>
);

export default memo(InstagramIcon);
