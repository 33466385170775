import { Socket } from 'socket.io-client';

import { SocketNotificationEventOutTypes } from '../socketEventsTypes/notifications';

type SaveMessageOnSocketNotConnectedType = (
  ev: SocketNotificationEventOutTypes,
  payload: any,
) => void;

type DataTupleType = [SocketNotificationEventOutTypes, any];

export const notificationRead = (
  socket: Socket | null,
  saveMessageOnSocketNotConnected: SaveMessageOnSocketNotConnectedType,
  notificationId: number,
) => {
  const data: DataTupleType = [
    SocketNotificationEventOutTypes.notificationRead,
    { notificationId },
  ];

  if (socket && socket.connected) {
    socket.emit(...data);
  } else {
    saveMessageOnSocketNotConnected(...data);
  }
};

export const notificationReadMultiple = (
  socket: Socket | null,
  saveMessageOnSocketNotConnected: SaveMessageOnSocketNotConnectedType,
  notificationIds: number[],
) => {
  const data: DataTupleType = [
    SocketNotificationEventOutTypes.notificationReadMultiple,
    { notificationIds },
  ];

  if (socket && socket.connected) {
    socket.emit(...data);
  } else {
    saveMessageOnSocketNotConnected(...data);
  }
};
