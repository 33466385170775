import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ShowProfileSuccessPopupType } from '_types/modals.interface';

interface PopupsState {
  showProfileSuccessPopup: ShowProfileSuccessPopupType | null;
}

export const popupsSliceInitialState: PopupsState = {
  showProfileSuccessPopup: null,
};

const popupsSlice = createSlice({
  name: 'popups',
  initialState: popupsSliceInitialState,
  reducers: {
    resetState: () => popupsSliceInitialState,
    actionHandleShowProfilePopup(
      state,
      action: PayloadAction<ShowProfileSuccessPopupType | null>,
    ) {
      state.showProfileSuccessPopup = action.payload;
    },
  },
});

export const { actionHandleShowProfilePopup, resetState } = popupsSlice.actions;

export default popupsSlice;
