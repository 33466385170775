import { createAsyncThunk } from '@reduxjs/toolkit';

import { Pagination } from '_types';

import NotificationService from '_services/notificationService';

export const fetchReadNotifications = createAsyncThunk(
  'notification/fetchReadNotifications',
  async (pagination: Pagination, { rejectWithValue }) => {
    try {
      return await NotificationService.getReadNotificationsPage(pagination);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
