import React, { memo } from 'react';

interface ArrowRightCircleIconProps {
  className?: string;
}
const ArrowRightCircleIcon: React.FC<ArrowRightCircleIconProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5C5.87827 16.5 3.84344 15.6571 2.34315 14.1569C0.842854 12.6566 0 10.6217 0 8.5C0 6.37827 0.842854 4.34344 2.34315 2.84315C3.84344 1.34285 5.87827 0.5 8 0.5C10.1217 0.5 12.1566 1.34285 13.6569 2.84315C15.1571 4.34344 16 6.37827 16 8.5C16 10.6217 15.1571 12.6566 13.6569 14.1569C12.1566 15.6571 10.1217 16.5 8 16.5ZM7.293 6.207C7.11084 6.0184 7.01005 5.7658 7.01233 5.5036C7.0146 5.2414 7.11977 4.99059 7.30518 4.80518C7.49059 4.61977 7.7414 4.5146 8.0036 4.51233C8.2658 4.51005 8.5184 4.61084 8.707 4.793L11.707 7.793C11.8945 7.98053 11.9998 8.23484 11.9998 8.5C11.9998 8.76516 11.8945 9.01947 11.707 9.207L8.707 12.207C8.5184 12.3892 8.2658 12.49 8.0036 12.4877C7.7414 12.4854 7.49059 12.3802 7.30518 12.1948C7.11977 12.0094 7.0146 11.7586 7.01233 11.4964C7.01005 11.2342 7.11084 10.9816 7.293 10.793L8.586 9.5H5C4.73478 9.5 4.48043 9.39464 4.29289 9.20711C4.10536 9.01957 4 8.76522 4 8.5C4 8.23478 4.10536 7.98043 4.29289 7.79289C4.48043 7.60536 4.73478 7.5 5 7.5H8.586L7.293 6.207Z"
      fill="white"
    />
  </svg>
);

export default memo(ArrowRightCircleIcon);
