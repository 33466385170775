import { createAsyncThunk } from '@reduxjs/toolkit';

import advisoryOrgService from '_services/advisoryOrgService';

export const fetchGetAdvisoryOrgDetails = createAsyncThunk(
  'advisoryOrg/fetchGetAdvisoryOrgDetails',
  async (id: number, { rejectWithValue }) => {
    try {
      return await advisoryOrgService.getAdvisoryOrgDetails(id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetAllAdvisoryOrgNames = createAsyncThunk(
  'advisoryOrg/fetchGetAllAdvisoryOrgNames',
  async (_, { rejectWithValue }) => {
    try {
      return await advisoryOrgService.getAllAdvisoryOrgNames();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAdvisorOrgFilterSettings = createAsyncThunk(
  'advisoryOrg/fetchAdvisorOrgFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await advisoryOrgService.getAdvisoryOrgFilterSettings();

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
