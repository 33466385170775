import React, { memo, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import CrossIconModal from 'app/components/Icons/CrossIconModal';
import LoginForm from 'app/components/LoginForm';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowLoginModal } from 'store/reducers/modal';
import { showLoginModalSelector } from 'store/selectors/modal';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import './style.scss';

interface LoginModalProps {
  classNames?: string;
}

const LoginModal: React.FC<LoginModalProps> = ({ classNames }) => {
  const dispatch = useAppDispatch();

  const showLoginModal = useAppSelector(showLoginModalSelector)!;

  const cancelModal = useCallback(() => {
    dispatch(actionHandleShowLoginModal(null));
  }, [dispatch]);

  const onBeforePathChange = useCallback(async () => {
    dispatch(actionHandleShowLoginModal(null));

    if (showLoginModal.onLoginSuccessful) {
      await showLoginModal.onLoginSuccessful();
    }
  }, [dispatch, showLoginModal.onLoginSuccessful]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const { ref } = useOnClickOutside(!!showLoginModal, cancelModal);

  return (
    <div className="login__modal">
      <div className={clsx('modal_block', classNames)} ref={ref}>
        <div className="modal_block__body">
          <LoginForm
            formType={showLoginModal.formType}
            onBeforePathChange={onBeforePathChange}
          />
        </div>
        <div className="close_btn" onClick={cancelModal}>
          <CrossIconModal className="close_btn__icon" />
        </div>
      </div>
    </div>
  );
};

export default memo(LoginModal);
