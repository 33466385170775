import isArray from 'lodash/isArray';
import { ValidationError } from 'yup';
import { ReactNode } from 'react';
import { TFunction } from 'i18next';

import { ProfileFieldKey } from '_constants/profile-fields/fields';

import { ProfileFieldsErrorsObject } from '_types/profile.interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type YupExceptionType = ValidationError & {
  inner: any[];
  stack: string;
};

export const constructErrorsObjectFromYupException = <T>(
  t: TFunction,
  exception: YupExceptionType,
  profileFieldName?: ProfileFieldKey,
): ProfileFieldsErrorsObject<T> => {
  let errorsObject = {};

  if (!exception.inner) {
    if (profileFieldName) {
      // occurred during onboarding field validation
      // eslint-disable-next-line no-console
      console.error(
        `An error occurred while validating the ${profileFieldName} field: `,
        exception.stack,
      );

      return [exception.message] as any as ProfileFieldsErrorsObject<T>;
    }

    return errorsObject;
  }

  exception.inner.forEach((error) => {
    if (!error.path) {
      errorsObject = error.errors;
      return;
    }

    // eslint-disable-next-line no-useless-escape
    const pathParts = error.path.split(/[\.\[\]]/).filter((o) => o);
    const partsCount = pathParts.length;

    let tempObj = errorsObject;
    pathParts.forEach((p, index) => {
      const isNextPartNumber = !Number.isNaN(
        parseInt(pathParts[index + 1], 10),
      );
      const part = isNextPartNumber ? `${p}[]` : p;
      if (index === partsCount - 1) {
        let message: ReactNode;

        const errorMessage = error.message;

        if (typeof errorMessage === 'string') {
          message = t(errorMessage, {
            params: {
              message: errorMessage,
              ...error.params,
            },
          });
        } else {
          message = t(errorMessage.message, {
            params: {
              ...error.params,
              ...errorMessage,
            },
          });
        }

        if (part in tempObj) {
          if (!isArray(tempObj[part])) {
            tempObj[part] = [tempObj[part]];
          }
          if (part !== 'firstName' && part !== 'lastName') {
            tempObj[part].push(message);
          }
        } else {
          tempObj[part] = message;
        }
      } else {
        if (!(part in tempObj)) {
          tempObj[part] = {};
        }
        tempObj = tempObj[part];
      }
    });
  });

  return errorsObject;
};

export const constructErrorMessagesListFromYupException = (
  exception: YupExceptionType,
) => {
  if (!exception.inner) {
    return [exception.message];
  }

  return exception.inner.map((error) => error.message);
};
