import { createSlice } from '@reduxjs/toolkit';

import { PartnerResourcesItemType } from '_types/partner-resources.interface';

import { fetchGetPartnerResourcesCards } from 'store/actions/partner-resources';

interface PartnerResourcesState {
  partnerResourcesCount: number;
  partnerResourcesList: PartnerResourcesItemType[];
  isLoading: boolean;
}

const partnerResourcesInitialState: PartnerResourcesState = {
  partnerResourcesCount: 0,
  partnerResourcesList: [],
  isLoading: false,
};

const partnerResourcesSlice = createSlice({
  name: 'partner-resources',
  initialState: partnerResourcesInitialState,
  reducers: {
    resetState: () => partnerResourcesInitialState,
    resetPartnerResourcesState: (state) => {
      state.partnerResourcesCount = 0;
      state.partnerResourcesList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPartnerResourcesCards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetPartnerResourcesCards.fulfilled,
      (state, action) => {
        state.partnerResourcesCount = action.payload.count;
        state.partnerResourcesList = action.payload.partnerResources;
        state.isLoading = false;
      },
    );
    builder.addCase(fetchGetPartnerResourcesCards.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default partnerResourcesSlice;
