import React, { memo } from 'react';

import Logo from 'app/components/Logo/Logo';

import HeaderProgressBar from './HeaderProgressBar';

interface OnboardingHeaderProps {
  progressInPercents?: number;
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
  progressInPercents,
}) => (
  <>
    <div className="header_wrapper">
      <Logo className="logo" />

      {progressInPercents !== undefined && (
        <div className="percent">{progressInPercents}%</div>
      )}

      {/* <LanguageSwitch /> */}
    </div>

    <HeaderProgressBar percent={progressInPercents!} />
  </>
);

export default memo(OnboardingHeader);
