import React, { memo } from 'react';

interface CoursesIconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}
const CoursesIcon: React.FC<CoursesIconProps> = ({
  className,
  color,
  width,
  height,
}) => (
  <svg
    width={width || '15'}
    height={height || '18'}
    viewBox="0 0 15 18"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 0.875H3.125C2.46259 0.87706 1.8279 1.14111 1.35951 1.60951C0.891114 2.0779 0.62706 2.71259 0.625 3.375V16.5C0.625 16.6658 0.690848 16.8247 0.808058 16.9419C0.925269 17.0592 1.08424 17.125 1.25 17.125H12.5C12.6658 17.125 12.8247 17.0592 12.9419 16.9419C13.0592 16.8247 13.125 16.6658 13.125 16.5C13.125 16.3342 13.0592 16.1753 12.9419 16.0581C12.8247 15.9408 12.6658 15.875 12.5 15.875H1.875C1.875 15.5435 2.0067 15.2255 2.24112 14.9911C2.47554 14.7567 2.79348 14.625 3.125 14.625H13.75C13.9158 14.625 14.0747 14.5592 14.1919 14.4419C14.3092 14.3247 14.375 14.1658 14.375 14V1.5C14.375 1.33424 14.3092 1.17527 14.1919 1.05806C14.0747 0.940848 13.9158 0.875 13.75 0.875ZM11.875 8.375L9.875 6.875C9.82144 6.83319 9.75545 6.81048 9.6875 6.81048C9.61955 6.81048 9.55356 6.83319 9.5 6.875L7.5 8.375V2.125H11.875V8.375Z"
      fill={color || '#D2EBFF'}
    />
  </svg>
);

export default memo(CoursesIcon);
