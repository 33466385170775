import React, { memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { phoneConstant } from '_constants/otherConstants';

import { useAppDispatch } from 'store/configureStore';

import { actionHandleShowProfileMissingDataSuccessModal } from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import ArrowRightCircleIcon from '../../../Icons/ArrowRightCircleIcon';
import BaseModal from '../../BaseModal';
import CheckMarkIcon from '../../../Icons/CheckMarkIcon';

import './style.scss';

const classNames = {
  modal: 'profile_missing_data_success_modal',
  modalInnerContainer: 'profile_missing_data_success_modal_container',
  modalHeader: 'profile_missing_data_success_modal_header',
  modalContent: 'profile_missing_data_success_modal_content',
  modalFooter: 'profile_missing_data_success_modal_footer',
};

const ProfileMissingDataSuccessModal: React.FC = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowProfileMissingDataSuccessModal(null));
    document.body.style.overflow = 'auto';
    navigate('/');
  }, [dispatch]);

  const headerIcon = useMemo(
    () => (
      <CheckMarkIcon
        className="profile_missing_data_success_modal_header-icon"
        color="var(--main-blue)"
        isThin
      />
    ),
    [],
  );

  return (
    <BaseModal
      closeOnBackgroundClick={false}
      onModalClose={closeModal}
      withCloseButton
      closeButtonIcon={<ArrowRightCircleIcon />}
      closeButtonSize={isPhone ? 'medium' : 'large'}
      closeButtonText={t('misc.go_to_homepage')}
      headerIcon={headerIcon}
      modalTitle={t('misc.thank_you')}
      classNames={classNames}
    >
      <p className="profile_missing_data_success_modal_description">
        {t('misc.profile_missing_data_success_modal_description')}
      </p>
    </BaseModal>
  );
};

export default memo(ProfileMissingDataSuccessModal);
