import type { BackendNotification } from '_types/notifications.interface';
import type { SessionInfoType } from '_types/sessions.interface';

import type { NotificationUserMentioned } from '../../types';

export enum SessionDataNotificationTypeEnum {
  BOOKED = 'BOOKED',
  SCHEDULED = 'SCHEDULED',
  RESCHEDULED = 'RESCHEDULED',
  CANCELED_TO_SBO = 'CANCELED_TO_SBO',
  CANCELED_TO_ADVISOR = 'CANCELED_TO_ADVISOR',
}

export type SessionDataNotificationSessionObject = SessionInfoType;

export type SessionDataNotificationNotificationObject = BackendNotification & {
  data: {
    session: SessionDataNotificationSessionObject;
    userMentioned: NotificationUserMentioned;
  };
};
