import clsx from 'clsx';
import React, { ReactNode, memo, useCallback, useRef, useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useTenantColors from 'utils/hooks/useTenantColors';

import TooltipTriangle from './TooltipTriangle';

import './style.scss';

interface TooltipProps {
  children: ReactNode;
  takeMaxWidth: boolean;
  className?: string;
  hover?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  takeMaxWidth,
  className,
  hover = false,
}) => {
  const { colorVariables } = useTenantColors();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const iconContainerRef = useRef<HTMLDivElement>(null);

  const onCloseTooltip = useCallback(() => setIsOpen(false), [setIsOpen]);

  const { ref: clickOutsideOfRef } = useOnClickOutside(isOpen, onCloseTooltip);

  const tooltipWidth =
    iconContainerRef?.current && takeMaxWidth
      ? window.innerWidth -
        (iconContainerRef?.current?.getBoundingClientRect()?.x || 0) -
        27
      : 'auto';

  return (
    <div ref={clickOutsideOfRef} className={clsx('tooltip', className)}>
      <div
        className="tooltip__question_mark"
        onClick={() => setIsOpen(true)}
        ref={iconContainerRef}
      >
        <MdOutlineInfo
          size={18}
          color={colorVariables?.icons.icon_secondary}
          id="tooltipIcon"
        />
      </div>

      {(isOpen || hover) && (
        <div className="tooltip__content">
          <TooltipTriangle className="tooltip__content__triangle" />

          <div
            className="tooltip__content__rectangle"
            style={{ width: tooltipWidth }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Tooltip);
