import { BackendNotification } from '_types/notifications.interface';

import {
  actionAddUnreadNotification,
  actionUnreadNotificationsSeen,
  actionSetUnreadNotifications,
} from 'store/reducers/notification';

import { SocketNotificationEventInTypes } from '../socketEventsTypes/notifications';
import { MessagesToHandlersType } from '../socketTypes';

const onUnreadNotificationsToSet = (
  dispatch: Function,
  notifications: BackendNotification[],
) => {
  dispatch(actionSetUnreadNotifications(notifications));
};

const onNotificationRead = (dispatch: Function, notificationId: number) => {
  dispatch(actionUnreadNotificationsSeen([notificationId]));
};

const onNotificationReadMultiple = (
  dispatch: Function,
  notificationIds: number[],
) => {
  dispatch(actionUnreadNotificationsSeen(notificationIds));
};

const onNotificationNew = (
  dispatch: Function,
  notification: BackendNotification,
) => {
  dispatch(actionAddUnreadNotification(notification));
};

export const notificationSocketEventHandlers: MessagesToHandlersType = {
  [SocketNotificationEventInTypes.unreadNotificationsToSet]:
    onUnreadNotificationsToSet,
  [SocketNotificationEventInTypes.notificationRead]: onNotificationRead,
  [SocketNotificationEventInTypes.notificationReadMultiple]:
    onNotificationReadMultiple,
  [SocketNotificationEventInTypes.notificationNew]: onNotificationNew,
};
