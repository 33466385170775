import React, { memo } from 'react';

import GlobalErrorPage from './GlobalErrorPage';

interface GlobalErrorBoundaryProps {
  children: React.ReactNode;
}

interface GlobalErrorBoundaryState {
  hasError: boolean;
}

class GlobalErrorBoundary extends React.Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(
      'An unexpected error occured.\n\n',
      error,
      '\n\nReact error info:',
      errorInfo,
    );
  }

  render() {
    if (this.state.hasError) {
      return <GlobalErrorPage />;
    }

    return this.props.children;
  }
}

export default memo(GlobalErrorBoundary);
