import about_us from './about_us.json';
import funding from './funding.json';
import glossary from './glossary.json';
import misc from './misc.json';
import myAccount from './myAccount.json';
import partner_resources from './partner_resources.json';

export const resourcesEnVerizon = {
  about_us,
  funding,
  glossary,
  misc,
  myAccount,
  partner_resources,
};
