import React, { memo, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import { platformLanguageChoices } from '_constants/headerConstants';
import { mainGrayColor } from '_constants/colorConstants';

import CheckedIcon from 'app/components/Icons/CheckedIcon';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsSelector } from 'store/selectors/tenant-settings';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import {
  applyPlatformLanguage,
  useCurrentPlatformLanguage,
} from 'utils/translations';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import WorldIcon from '../Icons/WorldIcon';

import './style.scss';

interface LanguageSwitchProps {
  classNames?: string;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = memo(({ classNames }) => {
  const { i18n } = useTenantTranslation();

  const currentLang = useCurrentPlatformLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { languagesAvailable } = useAppSelector(tenantSettingsSelector)!;

  const handleLangModal = useCallback(() => setIsMenuOpen(false), []);

  const languageChoicesAvailable = useMemo(
    () =>
      platformLanguageChoices.filter((elem) =>
        languagesAvailable.includes(elem.value),
      ),
    [languagesAvailable],
  );

  const changeLang = useCallback((lng) => {
    setIsMenuOpen(false);

    applyPlatformLanguage(lng, i18n);
  }, []);

  const { ref } = useOnClickOutside(isMenuOpen, handleLangModal);

  return (
    <div className={clsx('lang_switch', classNames)}>
      <div
        className="lang_switch__btn"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className={clsx('lang_switch__btn_icon', { active: isMenuOpen })}>
          <WorldIcon />
        </div>
      </div>

      {isMenuOpen && (
        <div className={clsx('modal_block', 'lang_switch__wrapper')} ref={ref}>
          {languageChoicesAvailable.map((elem) => (
            <div key={`${elem.value}`} onClick={() => changeLang(elem.value)}>
              {elem.translate} ({elem.initName})
              {currentLang === elem.value && (
                <CheckedIcon
                  className="lang_switch__wrapper_arrow"
                  color={mainGrayColor}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default LanguageSwitch;
