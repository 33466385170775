import React, { memo } from 'react';

interface CommunityIconProps {
  className?: string;
  color?: string;
}
const CommunityIcon: React.FC<CommunityIconProps> = ({ className, color }) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57519 16.3532L0.166025 17.3332L1.14603 12.924C0.500635 11.7168 0.163964 10.3687 0.166025 8.99984C0.166025 4.39734 3.89686 0.666504 8.49936 0.666504C13.1019 0.666504 16.8327 4.39734 16.8327 8.99984C16.8327 13.6023 13.1019 17.3332 8.49936 17.3332C7.13049 17.3352 5.78237 16.9986 4.57519 16.3532ZM4.33269 8.99984C4.33269 10.1049 4.77168 11.1647 5.55308 11.9461C6.33448 12.7275 7.39429 13.1665 8.49936 13.1665C9.60443 13.1665 10.6642 12.7275 11.4456 11.9461C12.227 11.1647 12.666 10.1049 12.666 8.99984H10.9994C10.9994 9.66288 10.736 10.2988 10.2671 10.7676C9.79829 11.2364 9.1624 11.4998 8.49936 11.4998C7.83632 11.4998 7.20043 11.2364 6.73159 10.7676C6.26275 10.2988 5.99936 9.66288 5.99936 8.99984H4.33269Z"
      fill={color || '#D2EBFF'}
    />
  </svg>
);

export default memo(CommunityIcon);
