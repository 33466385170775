import React, { memo } from 'react';
import Avatar from 'react-avatar';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { phoneConstant } from '_constants/otherConstants';

import Loader from 'app/components/Loader';
import useTenantColors from 'utils/hooks/useTenantColors';

import './style.scss';

interface AvatarCircleProps {
  firstName: string;
  lastName?: string;
  className?: string;
  src: string | null | undefined;
  size: { small: string; large: string };
  isLoading?: boolean;
  color?: string;
}

const AvatarCircle: React.FC<AvatarCircleProps> = ({
  firstName = '',
  lastName = '',
  className,
  src,
  size,
  isLoading,
  color,
}) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const { colorVariables } = useTenantColors();
  const name =
    `${firstName?.split(' ')[0] || ''} ${lastName?.split(' ')[0] || ''}`.trim();

  return (
    <div className={clsx('avatar__img', className)}>
      {isLoading ? (
        <Loader withContainer={false} withText={false} />
      ) : (
        <Avatar
          src={src || undefined}
          name={name}
          round
          color={color}
          size={isPhone ? size.small : size.large}
        />
      )}
    </div>
  );
};

export default memo(AvatarCircle);
