import React, { memo } from 'react';

interface FacebookBlackIconProps {
  className?: string;
}
const FacebookBlackIcon: React.FC<FacebookBlackIconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM12 5.25H10.557C10.0957 5.25 9.75 5.439 9.75 5.91675V6.75H12L11.8215 9H9.75V15H7.5V9H6V6.75H7.5V5.30775C7.5 3.79125 8.298 3 10.0958 3H12V5.25Z"
      fill="#2B2B2B"
    />
  </svg>
);

export default memo(FacebookBlackIcon);
