export const removeExistingElement = (
  elementName,
  attributeName,
  attributeValue,
  // elementValue?,
  // key?,
) => {
  if (
    attributeName === 'name' ||
    attributeName === 'property' ||
    attributeName === 'rel'
  ) {
    try {
      const isElementExists = document.querySelector(
        `${elementName}[${attributeName}="${attributeValue}"]`,
      );
      if (isElementExists) isElementExists.remove();
      return null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Got error in removeExistingElement :', error);
      return null;
    }
  }
  return null;
};

export const attributeConstructor = (data) => {
  try {
    return Object.keys(data)?.reduce((acc: any, attributeKey: string) => {
      acc[attributeKey] = data[attributeKey];
      return acc;
    }, {});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in attributeConstructor :', error);
    return null;
  }
};
