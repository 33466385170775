import React, { memo } from 'react';

interface CalendlyIconProps {
  className?: string;
}
const CalendlyIcon: React.FC<CalendlyIconProps> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8114 20.742C20.798 21.6438 19.5337 22.7688 17.228 22.7688H15.8521C14.1879 22.7688 12.6725 22.1645 11.5847 21.0674C10.5249 19.9983 9.93917 18.5293 9.93917 16.9395V15.0523C9.93917 13.4625 10.5249 11.9935 11.5847 10.9244C12.6725 9.82738 14.1879 9.22308 15.8521 9.22308H17.228C19.5337 9.22308 20.798 10.348 21.8114 11.2498C22.8619 12.1888 23.7731 12.9883 26.1996 12.9883C26.5808 12.9883 26.9432 12.9604 27.3058 12.8954C27.3058 12.8861 27.2966 12.8768 27.2966 12.8768C27.1478 12.5142 26.9804 12.1702 26.7853 11.8263L25.1582 9.01855C23.6708 6.4433 20.9189 4.85352 17.9439 4.85352H14.6992C11.7242 4.85352 8.9723 6.4433 7.48479 9.01855L5.85782 11.8263C4.37031 14.4014 4.37031 17.581 5.85782 20.1563L7.48479 22.964C8.9723 25.5392 11.7242 27.129 14.6992 27.129H17.9439C20.9189 27.129 23.6708 25.5392 25.1582 22.964L26.7853 20.1563C26.9804 19.8123 27.1478 19.4683 27.2966 19.1058C27.2966 19.0965 27.3058 19.0871 27.3058 19.0871C26.9432 19.0313 26.5808 18.9942 26.1996 18.9942C23.7824 19.0034 22.8712 19.803 21.8114 20.742Z"
      fill="#006BFF"
    />
    <path
      d="M17.2285 10.9238H15.8527C13.3146 10.9238 11.6504 12.7367 11.6504 15.0517V16.9297C11.6504 19.2446 13.3146 21.0575 15.8527 21.0575H17.2285C20.9195 21.0575 20.6312 17.2923 26.2094 17.2923C26.7393 17.2923 27.2693 17.3387 27.7807 17.4409C27.9479 16.4834 27.9479 15.5072 27.7807 14.5496C27.2693 14.6426 26.7393 14.6984 26.2094 14.6984C20.6312 14.6891 20.9195 10.9238 17.2285 10.9238Z"
      fill="#006BFF"
    />
    <path
      d="M30.9875 18.817C30.03 18.1104 28.9422 17.6549 27.7801 17.4411C27.7801 17.4503 27.7801 17.4596 27.7801 17.4689C27.6778 18.0175 27.5291 18.566 27.3152 19.0959C28.2914 19.2539 29.1932 19.6259 29.9649 20.1837C29.9649 20.193 29.9556 20.2023 29.9556 20.2116C29.5093 21.6526 28.84 23.0099 27.966 24.2464C27.1014 25.4643 26.0602 26.5427 24.8609 27.4446C22.3693 29.3132 19.4128 30.2987 16.2891 30.2987C14.3553 30.2987 12.4867 29.9176 10.7202 29.1738C9.01888 28.458 7.48487 27.4259 6.17401 26.1058C4.86314 24.7949 3.83118 23.2609 3.10601 21.5595C2.36227 19.8025 1.9811 17.9246 1.9811 16C1.9811 14.0662 2.36227 12.1976 3.10601 10.4312C3.82189 8.72982 4.85385 7.19581 6.17401 5.88494C7.48487 4.57408 9.01888 3.54212 10.7202 2.81695C12.4774 2.0732 14.3553 1.69203 16.2891 1.69203C19.4036 1.69203 22.3693 2.67751 24.8609 4.54619C26.0602 5.448 27.1014 6.51714 27.966 7.74433C28.84 8.98082 29.5093 10.3382 29.9556 11.7792C29.9556 11.7885 29.9649 11.7978 29.9649 11.8071C29.1932 12.3742 28.2914 12.7368 27.3152 12.8949C27.5291 13.4247 27.6778 13.964 27.7801 14.5218C27.7801 14.5311 27.7801 14.5404 27.7801 14.5497C28.9422 14.3359 30.03 13.8711 30.9875 13.1738C31.8987 12.4951 31.722 11.7327 31.5825 11.2865C29.5744 4.75073 23.4849 0 16.2891 0C7.44769 0 0.289062 7.16795 0.289062 16C0.289062 24.8415 7.45699 32 16.2891 32C23.4849 32 29.5744 27.2494 31.5825 20.7135C31.722 20.2487 31.8987 19.4864 30.9875 18.817Z"
      fill="#006BFF"
    />
    <path
      d="M27.3159 12.8949C26.9533 12.9508 26.5907 12.9879 26.2095 12.9879C23.783 12.9879 22.872 12.1791 21.8214 11.2494C20.808 10.3476 19.5343 9.22266 17.238 9.22266H15.8621C14.1979 9.22266 12.6825 9.82697 11.5948 10.924C10.5349 11.9931 9.94922 13.462 9.94922 15.0518V16.9392C9.94922 18.5288 10.5349 19.9979 11.5948 21.067C12.6825 22.164 14.1887 22.7683 15.8621 22.7683H17.238C19.5436 22.7683 20.808 21.6434 21.8214 20.7416C22.872 19.8026 23.783 19.0031 26.2095 19.0031C26.5907 19.0031 26.9533 19.0309 27.3159 19.096C27.5298 18.566 27.6784 18.0269 27.7808 17.469C27.7808 17.4597 27.7808 17.4505 27.7808 17.4412C27.2694 17.3481 26.7395 17.3017 26.2095 17.3017C20.6314 17.3017 20.9196 21.067 17.2287 21.067H15.8528C13.3147 21.067 11.6505 19.254 11.6505 16.9392V15.0518C11.6505 12.7369 13.3147 10.924 15.8528 10.924H17.2287C20.9196 10.924 20.6314 14.6892 26.2095 14.6892C26.7395 14.6892 27.2694 14.6428 27.7808 14.5498C27.7808 14.5405 27.7808 14.5313 27.7808 14.522C27.6784 13.9641 27.5205 13.4156 27.3159 12.8949Z"
      fill="#0AE8F0"
    />
    <path
      d="M27.3159 12.8949C26.9533 12.9508 26.5907 12.9879 26.2095 12.9879C23.783 12.9879 22.872 12.1791 21.8214 11.2494C20.808 10.3476 19.5343 9.22266 17.238 9.22266H15.8621C14.1979 9.22266 12.6825 9.82697 11.5948 10.924C10.5349 11.9931 9.94922 13.462 9.94922 15.0518V16.9392C9.94922 18.5288 10.5349 19.9979 11.5948 21.067C12.6825 22.164 14.1887 22.7683 15.8621 22.7683H17.238C19.5436 22.7683 20.808 21.6434 21.8214 20.7416C22.872 19.8026 23.783 19.0031 26.2095 19.0031C26.5907 19.0031 26.9533 19.0309 27.3159 19.096C27.5298 18.566 27.6784 18.0269 27.7808 17.469C27.7808 17.4597 27.7808 17.4505 27.7808 17.4412C27.2694 17.3481 26.7395 17.3017 26.2095 17.3017C20.6314 17.3017 20.9196 21.067 17.2287 21.067H15.8528C13.3147 21.067 11.6505 19.254 11.6505 16.9392V15.0518C11.6505 12.7369 13.3147 10.924 15.8528 10.924H17.2287C20.9196 10.924 20.6314 14.6892 26.2095 14.6892C26.7395 14.6892 27.2694 14.6428 27.7808 14.5498C27.7808 14.5405 27.7808 14.5313 27.7808 14.522C27.6784 13.9641 27.5205 13.4156 27.3159 12.8949Z"
      fill="#0AE8F0"
    />
  </svg>
);

export default memo(CalendlyIcon);
