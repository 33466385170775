import { RootState } from 'store/configureStore';

export const upcomingSessionsSelector = (store: RootState) =>
  store.sessions.upcoming;
export const pastSessionsSelector = (store: RootState) => store.sessions.past;
export const sessionSelector = (store: RootState) => store.sessions.session;

export const sessionGroupAllSelector = (store: RootState) =>
  store.sessions.groupAll;
export const sessionGroupOpenSelector = (store: RootState) =>
  store.sessions.groupOpen;
export const rateSessionSelector = (store: RootState) =>
  store.modals.showRateSessionModal;
export const allClientsCountSelector = (store: RootState) =>
  store.sessions.groupAll.count;
export const havingUpcomingSessionsClientsCountSelector = (store: RootState) =>
  store.sessions.groupOpen.count;
