import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from 'app/components/Logo/Logo';
import HomePageTabs from 'app/components/HomePageTabs/HomePageTabs';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'store/selectors/profile';

import GeneralHeaderProfilePart from './GeneralHeaderProfilePart';

const GeneralHeader: React.FC = () => {
  const navigate = useNavigate();
  const onLogoClick = useCallback(() => navigate('/'), [navigate]);

  const profileId = useAppSelector(profileIdSelector);

  return (
    <div className="header_wrapper">
      <Logo className="logo" onClick={onLogoClick} />

      {profileId && <HomePageTabs />}

      <GeneralHeaderProfilePart />
    </div>
  );
};

export default memo(GeneralHeader);
