import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  InlineWidget as CalendlyInlineWidget,
  useCalendlyEventListener,
} from 'react-calendly';
import { useMediaQuery } from 'react-responsive';

import {
  nextStreetWordConstant,
  phoneConstant,
  utmCampaignWordConstant,
} from '_constants/otherConstants';

import { CalendlyInterface } from '_types/calendly.interface';

import sessionsService from '_services/sessionsService';

import VideoPlayer from 'app/components/VideoPlayer';
import CrossIcon from 'app/components/Icons/CrossIcon';
import PlayIcon from 'app/components/Icons/PlayIcon';
import Button from 'app/components/Button';
import PreviewVideoModal from 'app/components/UploadVideo/components/PreviewVideoModal';

import { useAppSelector } from 'store/configureStore';

import { profileSboInfoSelector } from 'store/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { getCurrentTimeZone } from 'utils/moment';

import BookingModalFooter from './BookingModalFooter';
import BookingModalHeader from './BookingModalHeader';
import { calendlyStyles, stepComponents, calendlySteps } from './config';

import './style.scss';

interface BookingModalProps {
  onClose: () => void;
  advisor: any; // TODO: add specific type
  advisorId: number | null;
  isModalOpen: boolean;
  classNames?: string;
}

const BookingModal: React.FC<BookingModalProps> = ({
  onClose,
  advisor,
  advisorId,
  isModalOpen,
  classNames,
}) => {
  const { t } = useTenantTranslation();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const [step, setStep] = useState<number>(calendlySteps.stepTime);
  const [calendlyLink, setCalendlyLink] = useState<string>('');
  const [bookingData, setBookingData] = useState<CalendlyInterface>();
  const [isShowIntroMobile, setIsShowIntroMobile] = useState<boolean>(false);
  const sboInfo = useAppSelector(profileSboInfoSelector);

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const { invitee } = e.data.payload;
      const result = await sessionsService.createNewEvent(
        {
          inviteeUri: invitee.uri,
          advisorId,
        },
        getCurrentTimeZone(),
      );

      setBookingData(result);
      setStep(calendlySteps.stepFinal);
    },
  });

  const onNextClick = useCallback(() => {
    if (step === calendlySteps.stepFinal) {
      onClose();
    } else {
      setStep((prevState) => prevState + 1);
    }
  }, [step, onClose, setStep]);

  /* const onBackClick = useCallback(
    () => {
      setStep(calendlySteps.stepTime);
    }, [setStep]
  ); */

  const formatCalendlyLink = useCallback((link: string) => {
    const url = new URL(link);
    url.searchParams.set(utmCampaignWordConstant, nextStreetWordConstant);
    return url.toString();
  }, []);

  const onSelect = useCallback(
    (value: string) => {
      const newLink = formatCalendlyLink(advisor.advisorCalendlyLinks[value]);

      setCalendlyLink(newLink);
    },
    [setCalendlyLink, formatCalendlyLink],
  );

  const onShowIntroClick = useCallback(() => {
    setIsShowIntroMobile(true);
  }, [setIsShowIntroMobile]);

  const onMobileVideoClose = useCallback(() => {
    setIsShowIntroMobile(false);
  }, [setIsShowIntroMobile]);

  const { ref } = useOnClickOutside(isModalOpen, onClose);

  const StepComponent: React.FC<any> = stepComponents[step];
  const isVideoShown = advisor?.video && step !== calendlySteps.stepFinal;

  return (
    <div className="modal_wrapper">
      <div
        className={clsx(
          'modal_block',
          { modal_calendly: step === calendlySteps.stepCalendly },
          classNames,
        )}
        ref={ref}
      >
        {step === calendlySteps.stepCalendly && (
          <>
            <Button
              type="btn"
              btnLook="filled"
              onClick={onClose}
              size="medium"
              className={clsx('modal_close_button', {
                show: step === calendlySteps.stepCalendly,
              })}
            >
              <CrossIcon />
            </Button>
            <CalendlyInlineWidget
              url={calendlyLink}
              styles={calendlyStyles}
              prefill={{
                email: sboInfo?.email || '',
                firstName: sboInfo?.firstName || '',
                lastName: sboInfo?.lastName || '',
                name: `${sboInfo?.firstName} ${sboInfo?.lastName}` || '',
              }}
            />
          </>
        )}
        {step !== calendlySteps.stepCalendly && (
          <>
            {isVideoShown &&
              (isPhone ? (
                <PreviewVideoModal
                  isOpen={isShowIntroMobile}
                  videoUrl={advisor?.video!}
                  onModalClose={onMobileVideoClose}
                />
              ) : (
                <div className={clsx('modal_video')}>
                  <VideoPlayer url={advisor?.video!} />
                </div>
              ))}
            <div className="modal_right_content">
              <BookingModalHeader
                firstName={advisor.firstName || ''}
                lastName={advisor.lastName || ''}
                photo={advisor.photo}
                company={advisor.advisoryOrgName}
                bio={advisor.bio}
              />
              {advisor?.video && (
                <Button
                  type="btn"
                  btnLook="filled-gray"
                  value={t('misc.watch_intro')}
                  onClick={onShowIntroClick}
                  size="large"
                  leftIcon={<PlayIcon />}
                  className="modal_watch_intro"
                />
              )}
              <div className="modal_body">
                <StepComponent onSelect={onSelect} {...bookingData} />
              </div>
              <BookingModalFooter
                active={!!calendlyLink}
                step={step}
                totalSteps={calendlySteps.stepFinal}
                onCancelClick={onClose}
                onNextClick={onNextClick}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(BookingModal);
