import { createContext } from 'react';

import { ProfileFieldIdsContextInterface } from '_types/profile.interface';

export const defaultProfileFieldIdsContextData: ProfileFieldIdsContextInterface =
  {
    profileFieldIds: {},
    addProfileFieldId: () => {},
    clearProfileFieldIds: () => {},
  };

const ProfileFieldIdsContext = createContext<ProfileFieldIdsContextInterface>(
  defaultProfileFieldIdsContextData,
);

export default ProfileFieldIdsContext;
