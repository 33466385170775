import { createAsyncThunk } from '@reduxjs/toolkit';

import tenantSettingsService from '_services/tenantSettingsService';
import { PageConfigurationTypeEnum } from '_types/tenant-settings/tenant-page-configuration';

export const fetchGetTenantSettings = createAsyncThunk(
  'user/fetchGetTenantSettings',
  (_, { rejectWithValue }) =>
    tenantSettingsService.getTenantSettings().catch(rejectWithValue),
);

export const fetchGetTenantPageConfiguration = createAsyncThunk(
  'user/fetchGetTenantPageConfiguration',
  (type: PageConfigurationTypeEnum, { rejectWithValue }) =>
    tenantSettingsService
      .getTenantPageConfiguration(type)
      .catch(rejectWithValue),
);
