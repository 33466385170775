import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { HeaderTabs } from '_constants/headerConstants';

import { LoginFormType } from '_types/modals.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'store/selectors/profile';
import { tenantSettingsTenantFeatureFlagsSelector } from 'store/selectors/tenant-settings';
import {
  actionHandleShowLoginModal,
  actionHandleShowMenuModal,
} from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useTenantType from 'utils/hooks/useTenantType';

import { headerTabsConstantsFn } from './utils';

import './style.scss';

interface HomePageTabsProps {
  inModal?: boolean;
  withDividers?: boolean;
  className?: string;
}

const HomePageTabs: React.FC<HomePageTabsProps> = ({
  inModal = false,
  withDividers = false,
  className,
}) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const tenantType = useTenantType();
  const tenantFeatureFlags = useAppSelector(
    tenantSettingsTenantFeatureFlagsSelector,
  );
  // const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const userRole = useAppSelector(profileRoleSelector);

  const headerTabsConstants = useMemo(
    () => headerTabsConstantsFn(userRole, tenantFeatureFlags, t, tenantType),
    [userRole, tenantFeatureFlags, t, tenantType],
  );

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
  }, [dispatch]);

  const onLoginClick = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
    dispatch(actionHandleShowLoginModal({ formType: LoginFormType.login }));
  }, [dispatch]);

  if (!headerTabsConstants?.length) {
    return null;
  }

  return (
    <div
      className={clsx('home_tabs', className, {
        'with-dividers': withDividers,
        'in-modal': inModal,
      })}
    >
      {headerTabsConstants?.map((elem: HeaderTabs, index) => (
        <Fragment key={elem.name}>
          <NavLink
            to={elem.path}
            className={({ isActive }) =>
              clsx('home_tabs_item', {
                active: elem.path === '#' ? false : isActive,
              })
            }
            key={elem.name}
            onClick={elem.path === '#' ? onLoginClick : closeModal}
          >
            {/* <div className="home_tabs_item__icon">{elem.getIcon(tenantStyling?.shades08)}</div> */}
            <div className="home_tabs_item__text">{elem.name}</div>
          </NavLink>

          {withDividers && index !== headerTabsConstants.length - 1 && (
            <div className="tabs-horizontal-divider" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(HomePageTabs);
