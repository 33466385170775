import { useEffect } from 'react';

import { fetchGetOnboardingQuestionConfigurationForIndustry } from 'store/actions/onboarding';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { onboardingQuestionsIndustryConfigurationSelector } from 'store/selectors/onboarding';

const useIndustryFieldConfiguration = () => {
  const dispatch = useAppDispatch();

  const industryFieldConfiguration = useAppSelector(
    onboardingQuestionsIndustryConfigurationSelector,
  );

  useEffect(() => {
    if (!industryFieldConfiguration) {
      dispatch(fetchGetOnboardingQuestionConfigurationForIndustry());
    }
  }, []);

  return industryFieldConfiguration;
};

export default useIndustryFieldConfiguration;
