import React, { memo } from 'react';

interface FacebookIconProps {
  className?: string;
}
const FacebookIcon: React.FC<FacebookIconProps> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3333 0H6.66667C2.98533 0 0 2.98533 0 6.66667V25.3333C0 29.0147 2.98533 32 6.66667 32H25.3333C29.016 32 32 29.0147 32 25.3333V6.66667C32 2.98533 29.016 0 25.3333 0ZM21.3333 9.33333H18.768C17.948 9.33333 17.3333 9.66933 17.3333 10.5187V12H21.3333L21.016 16H17.3333V26.6667H13.3333V16H10.6667V12H13.3333V9.436C13.3333 6.74 14.752 5.33333 17.948 5.33333H21.3333V9.33333Z"
      fill="#4267B2"
    />
  </svg>
);

export default memo(FacebookIcon);
