import React, { memo, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { ProfileAdvisor } from '_types/profile.interface';

import { MyAdvisoryOrgAdminProfileErrorsType } from 'app/pages/MyAccount/components/MyProfile/MyAdvisoryOrgAdminProfile/validationSchemas';
import AdvisorIndustriesSelectComponent from 'app/components/ProfileFields/advisor/AdvisorIndustriesSelectComponent';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import {
  profileInfoSelector,
  profileMissingDataErrorsSelector,
} from 'store/selectors/profile';
import {
  actionChangeProfileMissingDataValidationErrors,
  actionEditAdvisor,
} from 'store/reducers/profile';
import { showProfileMissingDataModalSelector } from 'store/selectors/modal';

import './styles.scss';

const AdvisoryOrgMissingDataForm: React.FC = () => {
  const errors = useAppSelector(
    profileMissingDataErrorsSelector<MyAdvisoryOrgAdminProfileErrorsType>,
  );
  const showProfileMissingDataModal = useAppSelector(
    showProfileMissingDataModalSelector,
  );

  const dispatch = useAppDispatch();

  const profileInfo = useAppSelector(profileInfoSelector);

  const onChangeAdvisorData = useCallback(
    (key: keyof ProfileAdvisor) => (value: unknown) => {
      dispatch(actionEditAdvisor({ [key]: value }));

      if (errors.advisorData?.industries) {
        const newErrors = cloneDeep(errors);

        if (Object.keys(errors.advisorData).length === 1) {
          delete newErrors.advisorData;
        } else {
          delete newErrors.advisorData?.industries;
        }

        dispatch(actionChangeProfileMissingDataValidationErrors(newErrors));
      }
    },
    [dispatch],
  );

  const findMissingField = useCallback(
    (field: string) =>
      showProfileMissingDataModal?.missingFields.find(
        (value) => value === field,
      ),
    [showProfileMissingDataModal?.missingFields],
  );

  return (
    <div>
      {findMissingField('advisorData.industries') && (
        <AdvisorIndustriesSelectComponent
          onChange={onChangeAdvisorData('industries')}
          errors={errors.advisorData?.industries}
          value={profileInfo?.advisorData?.industries}
          titleClassName="field_name no_sub_title"
        />
      )}
    </div>
  );
};

export default memo(AdvisoryOrgMissingDataForm);
