import React, { memo, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { PhoneMultiFactorGenerator, PhoneAuthProvider } from '@firebase/auth';

import MFACodeEnterForm from 'app/components/MFACodeEnterForm';
import { MFACodeUsedInFormEnum } from 'app/components/MFACodeEnterForm/utils';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import type { MultiFactorResolver } from '@firebase/auth';

interface MFACodeVerificationProps {
  verificationId: string;
  onCodeVerifiedSuccessfully: () => Promise<void>;
  onResendCode: () => Promise<void>;
  goToPreviousPageStage: () => void;
  multifactorResolver: MultiFactorResolver;
}

const MFACodeVerification: React.FC<MFACodeVerificationProps> = ({
  verificationId,
  onCodeVerifiedSuccessfully,
  onResendCode,
  goToPreviousPageStage,
  multifactorResolver,
}) => {
  const { t } = useTenantTranslation();

  const onVerificationCodeSubmit = useCallback(
    async (verificationCode: string) => {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multifactorResolver.resolveSignIn(multiFactorAssertion);

      await onCodeVerifiedSuccessfully();
    },
    [verificationId, multifactorResolver, onCodeVerifiedSuccessfully],
  );

  const onCodeResend = useCallback(async () => {
    try {
      await onResendCode();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(t('misc.error_occurred_contact_administrator'));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [t, onResendCode]);

  if (
    multifactorResolver.hints[0].factorId !==
    PhoneMultiFactorGenerator.FACTOR_ID
  ) {
    // eslint-disable-next-line
    alert(t('auth.unsupported_second_factor'));

    return <Navigate to="/auth/login" />;
  }

  const phoneNumberString = (multifactorResolver.hints[0] as any).phoneNumber;

  return (
    <MFACodeEnterForm
      mfaCodeUsedInFormType={MFACodeUsedInFormEnum.login_form}
      phoneNumber={phoneNumberString}
      goToPreviousPageStage={goToPreviousPageStage}
      onCodeResend={onCodeResend}
      onFormSubmit={onVerificationCodeSubmit}
    />
  );
};

export default memo(MFACodeVerification);
