import React, { ReactNode, memo, useEffect, useState } from 'react';
import { fetchAndActivate } from 'firebase/remote-config';

import Loader from 'app/components/Loader';

import { remoteConfig } from './firebase';

interface FirebaseRemoteConfigProviderInterface {
  children: ReactNode;
}

const FirebaseRemoteConfigInitialization: React.FC<
  FirebaseRemoteConfigProviderInterface
> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      setIsInitialized(true);
    });
  }, []);

  if (!isInitialized) {
    return <Loader withText={false} />;
  }

  return <>{children}</>;
};

export default memo(FirebaseRemoteConfigInitialization);
