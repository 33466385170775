// This component is used as a wrapper for a profile field input component to add
// field's container, title, subtitle as well as container/title/subtitle css class

import React, { ReactNode, memo } from 'react';
import clsx from 'clsx';

import './style.scss';

export interface ProfileFieldWrapperProps {
  id?: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  children?: ReactNode;
  fieldClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  addAsteriskToTitle?: boolean;
}

const ProfileFieldWrapper: React.FC<ProfileFieldWrapperProps> = ({
  id,
  title,
  subTitle,
  children,
  fieldClassName,
  titleClassName,
  subTitleClassName,
  addAsteriskToTitle,
}) => (
  <div id={id} className={clsx('profile_field', fieldClassName)}>
    {!!title && (
      <p className={clsx('profile_field__title', titleClassName)}>
        {title}
        {addAsteriskToTitle && <>*</>}
      </p>
    )}

    {!!subTitle && (
      <p className={clsx('profile_field__subtitle', subTitleClassName)}>
        {subTitle}
      </p>
    )}

    {children}
  </div>
);

export default memo(ProfileFieldWrapper);
