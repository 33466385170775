import React, { memo, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowProfileMissingDataModal } from 'store/reducers/modal';
import { showProfileMissingDataModalSelector } from 'store/selectors/modal';
import { profileInfoSelector } from 'store/selectors/profile';
import { onboardingProgressSelector } from 'store/selectors/onboarding';

import ProfileMissingDataModal from '..';
import { getProfileMissingData } from '../utils';

const ProfileMissingDataModalWrapper: React.FC = () => {
  const dispatch = useAppDispatch();

  const showProfileMissingDataModal = useAppSelector(
    showProfileMissingDataModalSelector,
  );
  const profileInfo = useAppSelector(profileInfoSelector);
  const onboardingProgress = useAppSelector(onboardingProgressSelector);

  useEffect(() => {
    if (!profileInfo || !onboardingProgress?.isOnboardingCompleted) {
      return;
    }

    const missingFields = getProfileMissingData(profileInfo);

    if (missingFields.length) {
      dispatch(actionHandleShowProfileMissingDataModal({ missingFields }));
      document.body.style.overflow = 'hidden';
    }
  }, [profileInfo, onboardingProgress?.isOnboardingCompleted]);

  return showProfileMissingDataModal ? <ProfileMissingDataModal /> : null;
};

export default memo(ProfileMissingDataModalWrapper);
