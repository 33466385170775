import { useContext, useEffect, useState } from 'react';

import FirebaseAuthDataContext from 'utils/contexts/FirebaseAuthDataContext';
import {
  FirebaseAuthContextData,
  FirebaseReloadIntervalData,
} from 'utils/contexts/FirebaseAuthDataContext/utils';

import { getFirebaseUserMFAPhoneNumber } from './utils';

export const useCurrentFirebaseUser =
  (): FirebaseAuthContextData['currentUser'] => {
    const { currentUser } = useContext(FirebaseAuthDataContext);

    return currentUser;
  };

export const useCurrentAccessToken =
  (): FirebaseAuthContextData['accessToken'] => {
    const { accessToken } = useContext(FirebaseAuthDataContext);

    return accessToken;
  };

export const useIsFirebaseInitializing =
  (): FirebaseAuthContextData['isFirebaseInitializing'] => {
    const { isFirebaseInitializing } = useContext(FirebaseAuthDataContext);

    return isFirebaseInitializing;
  };

export const useCurrentMFAPhoneNumber = (): string | null => {
  const { currentUser } = useContext(FirebaseAuthDataContext);

  return getFirebaseUserMFAPhoneNumber(currentUser);
};

export const useCurrentFirebaseReloadInterval = (interval: number) => {
  // This hook rewrites the default or parent's (if set) firebase user reload interval.
  // When a component, where this hook is used, is unmounted, the default or parent's (if set)
  // firebase user reload interval will be set back automatically.
  const { setFirebaseUserReloadInterval, firebaseUserReloadInterval } =
    useContext(FirebaseAuthDataContext);
  const [initialInterval] = useState<
    FirebaseReloadIntervalData['firebaseUserReloadInterval']
  >(firebaseUserReloadInterval);

  useEffect(() => {
    setFirebaseUserReloadInterval(interval);

    return () => {
      setFirebaseUserReloadInterval(initialInterval);
    };
  }, [interval]);
};
