import { useCallback, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import {
  NotificationsKeptType,
  SaveMessageOnSocketNotConnectedType,
} from '_types/socket.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantFeatureFlagsSelector } from 'store/selectors/tenant-settings';

import { addSocketMessages, removeSocketMessages } from 'utils/socket';
import SocketContext from 'utils/contexts/SocketContext';

import type { FirebaseAuthContextData } from 'utils/contexts/FirebaseAuthDataContext/utils';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const useManageSocketConnection = (
  accessToken: FirebaseAuthContextData['accessToken'],
) => {
  const dispatch = useAppDispatch();
  const tenantFeatureFlags = useAppSelector(
    tenantSettingsTenantFeatureFlagsSelector,
  );

  const { setSocketData } = useContext(SocketContext);
  const [notificationsKept, setNotificationsKept] = useState<
    NotificationsKeptType[]
  >([]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const saveMessageOnSocketNotConnected =
    useCallback<SaveMessageOnSocketNotConnectedType>(
      (ev, payload) => {
        setNotificationsKept((prevState) => [...prevState, [ev, payload]]);
      },
      [setNotificationsKept],
    );

  const setSocketToContext = useCallback(
    (socketToSet: Socket | null) => {
      setSocketData({ socket: socketToSet, saveMessageOnSocketNotConnected });
    },
    [saveMessageOnSocketNotConnected],
  );

  useEffect(() => {
    if (!accessToken || !tenantFeatureFlags?.users) {
      return () => {};
    }

    const randomString = uuidv4();

    const newSocket = io(baseUrl!, {
      auth: {
        token: accessToken,
      },
      transports: ['websocket'],
      withCredentials: true,
      query: { deviceId: randomString },
      autoConnect: false,
    });

    addSocketMessages(newSocket, dispatch);

    if (!newSocket.connected) {
      newSocket.connect();
    }

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      removeSocketMessages(newSocket);
      setSocket(null);
    };
  }, [accessToken]);

  useEffect(() => {
    if (socket && socket.connected) {
      notificationsKept.forEach(([ev, payload]) => socket.emit(ev, payload));
      setNotificationsKept([]);
    }
  }, [!!socket, socket?.connected]);

  useEffect(() => {
    setSocketToContext(socket);
  }, [socket]);
};

export default useManageSocketConnection;
