import { TFunction } from 'i18next';

import { PatchProfileData, ProfileAdvisor } from '_types/profile.interface';
import { UserRolesEnum } from '_types/user.interface';
import { LoginFormType } from '_types/modals.interface';

import OnboardingService from '_services/onboardingService';

import { AppDispatch } from 'store/configureStore';

import { fetchPutOnboardingData } from 'store/actions/onboarding';
import { fetchPatchProfileInfo } from 'store/actions/profile';
import { actionHandleShowLoginModal } from 'store/reducers/modal';

export const processMFAPhoneNumberRequestError = (
  e: any,
  t: TFunction,
): string => {
  if (e?.code === 'auth/second-factor-already-in-use') {
    return t('auth.mfa_second_factor_in_use');
  }
  if (e?.code === 'auth/invalid-phone-number') {
    return t('auth.mfa_invalid_phone_number');
  }
  if (e?.code === 'auth/unverified-email') {
    return t('auth.mfa_email_not_verified');
  }
  if (
    e?.code === 'auth/too-many-requests' ||
    e?.response?.data?.error?.message === 'TOO_MANY_ATTEMPTS_TRY_LATER'
  ) {
    return t('auth.too_many_attempts_try_later');
  }

  return t('misc.error_occurred_contact_administrator');
};

export const updateUserPhoneNumberAfterMFASetup = async (
  dispatch: AppDispatch,
  profileRole: UserRolesEnum,
  newPhoneNumber: string,
  isOnboardingCompleted: boolean,
) => {
  const patchProfileData: PatchProfileData = { hasSkippedMFASetup: true };

  const isAdvisorOrAdmin =
    profileRole === UserRolesEnum.ADVISORY_ORG_ADMIN ||
    profileRole === UserRolesEnum.ADVISOR;

  if (isAdvisorOrAdmin) {
    if (isOnboardingCompleted) {
      patchProfileData.advisorData = {
        phoneNumber: newPhoneNumber,
      } as ProfileAdvisor;
    } else {
      const onboardingData = await OnboardingService.getOnboardingData();

      onboardingData.advisorData!.phoneNumber = newPhoneNumber;

      await dispatch(fetchPutOnboardingData(onboardingData));
    }
  }

  await dispatch(fetchPatchProfileInfo(patchProfileData));
};

export const catchMFAOldCredentialsError = (
  e: any,
  dispatch: AppDispatch,
): boolean => {
  if (
    e?.code === 'auth/requires-recent-login' ||
    e?.response?.data?.error?.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
  ) {
    dispatch(
      actionHandleShowLoginModal({ formType: LoginFormType.reauthenticate }),
    );
    return true;
  }

  return false;
};
