import React, { memo } from 'react';

interface OpenInNewTabIconProps {
  className?: string;
  fillColor?: string;
}
const OpenInNewTabIcon: React.FC<OpenInNewTabIconProps> = ({
  className,
  fillColor,
}) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4996 3C11.2344 3 10.98 3.10536 10.7925 3.29289C10.6049 3.48043 10.4996 3.73478 10.4996 4C10.4996 4.26522 10.6049 4.51957 10.7925 4.70711C10.98 4.89464 11.2344 5 11.4996 5H14.0856L7.79259 11.293C7.69708 11.3852 7.6209 11.4956 7.56849 11.6176C7.51608 11.7396 7.4885 11.8708 7.48734 12.0036C7.48619 12.1364 7.51149 12.2681 7.56177 12.391C7.61205 12.5139 7.68631 12.6255 7.7802 12.7194C7.87409 12.8133 7.98574 12.8875 8.10864 12.9378C8.23154 12.9881 8.36321 13.0134 8.49599 13.0123C8.62877 13.0111 8.75999 12.9835 8.882 12.9311C9.004 12.8787 9.11435 12.8025 9.20659 12.707L15.4996 6.414V9C15.4996 9.26522 15.6049 9.51957 15.7925 9.70711C15.98 9.89464 16.2344 10 16.4996 10C16.7648 10 17.0192 9.89464 17.2067 9.70711C17.3942 9.51957 17.4996 9.26522 17.4996 9V4C17.4996 3.73478 17.3942 3.48043 17.2067 3.29289C17.0192 3.10536 16.7648 3 16.4996 3H11.4996Z"
      fill={fillColor || '#009999'}
    />
    <path
      d="M5.5 5C4.96957 5 4.46086 5.21071 4.08579 5.58579C3.71071 5.96086 3.5 6.46957 3.5 7V15C3.5 15.5304 3.71071 16.0391 4.08579 16.4142C4.46086 16.7893 4.96957 17 5.5 17H13.5C14.0304 17 14.5391 16.7893 14.9142 16.4142C15.2893 16.0391 15.5 15.5304 15.5 15V12C15.5 11.7348 15.3946 11.4804 15.2071 11.2929C15.0196 11.1054 14.7652 11 14.5 11C14.2348 11 13.9804 11.1054 13.7929 11.2929C13.6054 11.4804 13.5 11.7348 13.5 12V15H5.5V7H8.5C8.76522 7 9.01957 6.89464 9.20711 6.70711C9.39464 6.51957 9.5 6.26522 9.5 6C9.5 5.73478 9.39464 5.48043 9.20711 5.29289C9.01957 5.10536 8.76522 5 8.5 5H5.5Z"
      fill={fillColor || '#009999'}
    />
  </svg>
);

export default memo(OpenInNewTabIcon);
