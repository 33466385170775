import React, { memo } from 'react';

import { UserRolesEnum } from '_types/user.interface';

import AdvisoryOrgMissingDataForm from '../AdvisoryOrgMissingDataForm';
import AdvisorMissingDataForm from '../AdvisorMissingDataForm';
import SboMissingDataForm from '../SboMissingDataForm';

interface ProfileMissingDataFormProps {
  role: UserRolesEnum;
}

const ProfileMissingDataForm = ({ role }: ProfileMissingDataFormProps) => {
  switch (role) {
    case UserRolesEnum.ADVISORY_ORG_ADMIN:
      return <AdvisoryOrgMissingDataForm />;
    case UserRolesEnum.ADVISOR:
      return <AdvisorMissingDataForm />;
    case UserRolesEnum.SBO:
      return <SboMissingDataForm />;
    default:
      return null;
  }
};

export default memo(ProfileMissingDataForm);
