import React, { ReactNode, memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import QuestionIcon from 'app/components/Icons/QuestionIcon';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import './style.scss';

interface SelectButtonHintProps {
  hint: string | ReactNode;
  position?: 'right' | 'left';
}

const SelectButtonHint: React.FC<SelectButtonHintProps> = ({
  hint,
  position = 'right',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseHint = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpenHint = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setIsOpen((value) => !value);
    },
    [],
  );

  const { ref } = useOnClickOutside(isOpen, handleCloseHint);

  return (
    <div
      className={clsx('select_button_hint', {
        position_left: position === 'left',
      })}
      ref={ref}
    >
      <div className="select_button_hint__block">
        <div
          className="select_button_hint__block__icon"
          onClick={handleOpenHint}
        >
          <QuestionIcon />
        </div>
        <div
          className={clsx('select_button_hint__block__popup', {
            open: isOpen,
          })}
        >
          <div className="select_button_hint__block__triangle">
            <div />
            <div />
            <div />
          </div>
          <div className="select_button_hint__block__text">{hint}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectButtonHint, isEqual);
