import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { PageAction } from '@newrelic/browser-agent/features/page_action';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { enabled: true },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    sa: 1,
  },
  loader_config: {
    accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    trustKey: process.env.REACT_APP_NEW_RELIC_TRUSTKEY,
    agentID: process.env.REACT_APP_NEW_RELIC_AGENTID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
  },
  features: [PageAction, Spa],
};

try {
  // eslint-disable-next-line no-new
  new BrowserAgent({ ...options });
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn(
    'An error occurred while creating BrowserAgent for New Relic',
    e,
  );
}

export const newRelic = new MicroAgent({ ...options });

export const addNewRelicPageAction = (name: string, data: object) => {
  try {
    newRelic.addPageAction(name, { ...data });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('An error occurred while adding page action to New Relic', e);
  }
};
