import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';

const ZendeskInitializerWrapper: React.FC = () => {
  useEffect(() => {
    try {
      ZendeskAPI('webWidget', 'hide');
      ZendeskAPI('webWidget:on', 'userEvent', (event) => {
        const separateWindowEventText = 'Web Widget Minimised';
        if (event.action === separateWindowEventText) {
          ZendeskAPI('webWidget', 'hide');
        }
      });
    } catch (error) {
      /* empty */
    }
  }, []);

  return <Outlet />;
};

export default memo(ZendeskInitializerWrapper);
