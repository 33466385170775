import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from 'app/components/Logo/Logo';

const LogoOnlyHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="header_wrapper first-page">
      <Logo className="logo" onClick={() => navigate('/')} />

      {/* <div className="header_wrapper_lang"><LanguageSwitch /></div> */}
    </div>
  );
};

export default memo(LogoOnlyHeader);
