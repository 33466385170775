import { UserRolesEnum } from '_types/user.interface';

import type { NotificationUserMentioned } from '../../types';

export const getNotificationLogoHref = (
  logoUserInfo: NotificationUserMentioned,
): string => {
  switch (logoUserInfo?.userRole) {
    case UserRolesEnum.SBO:
      return `/clients/${logoUserInfo.id}`;
    case UserRolesEnum.ADVISORY_ORG_ADMIN:
    case UserRolesEnum.ADVISOR:
      return `/advisors/${logoUserInfo.id}`;
    default:
      return '';
  }
};
