import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { CgArrowRightO } from 'react-icons/cg';

import { ResetPasswordModalTitleType } from '_types/modals.interface';
import { ProfileFieldErrorType } from '_types/profile.interface';

import Button from 'app/components/Button';
import FormikInputField from 'app/components/FormikFields/FormikInputField';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowResetPasswordModal } from 'store/reducers/modal';
import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import {
  constructErrorsObjectFromYupException,
  YupExceptionType,
} from 'utils/validationSchemas';

import {
  PasswordResetObject,
  changeUserPassword,
  initialPasswordResetObject,
  passwordResetValidationSchema,
  resetUserPassword,
} from './utils';

import './style.scss';

interface ResetPasswordFormProps {
  type: ResetPasswordModalTitleType;
  resetPasswordToken?: string | null;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  type,
  resetPasswordToken,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();

  const [commonError, setCommonError] = useState<
    ProfileFieldErrorType | undefined
  >(undefined);
  const tenantStyle = useAppSelector(tenantSettingsTenantStylingSelector);

  const onResetPasswordModalCloseClick = useCallback(
    () => dispatch(actionHandleShowResetPasswordModal(null)),
    [dispatch],
  );

  const validateWithTranslationFn = useCallback(
    async (values: PasswordResetObject) => {
      try {
        await passwordResetValidationSchema.validate(values, {
          abortEarly: false,
        });
      } catch (e: any) {
        return constructErrorsObjectFromYupException(t, e as YupExceptionType);
      }

      return {};
    },
    [t],
  );

  const onFormSubmit = useCallback(
    async (passwordResetObject: PasswordResetObject, { setErrors }) => {
      setCommonError(undefined);

      if (type === ResetPasswordModalTitleType.profile) {
        await changeUserPassword(
          passwordResetObject,
          setErrors,
          setCommonError,
          t,
          dispatch,
        );
      } else {
        await resetUserPassword(
          passwordResetObject,
          resetPasswordToken || '',
          setErrors,
          setCommonError,
          t,
          navigate,
          dispatch,
          tenantStyle?.colorVariables.text.text_accent_1,
        );
      }
    },
    [type, t, dispatch, navigate, setCommonError, tenantStyle],
  );

  const passwordChecksList = useMemo(
    () => [
      t('auth.least_characters_long'),
      t('auth.include_uppercase_character'),
      t('auth.include_special_character'),
    ],
    [t],
  );

  return (
    <Formik
      initialValues={initialPasswordResetObject}
      validate={validateWithTranslationFn}
      onSubmit={onFormSubmit}
    >
      {({ errors, touched, handleBlur, setFieldTouched }) => (
        <Form
          className={clsx('reset_password_form', {
            login_page: type === ResetPasswordModalTitleType.profile,
          })}
        >
          <div className="reset_password_form__body">
            <div className="reset_password_form__body__head">
              <p className="reset_password_form__body__head__title">
                {t('auth.change_password')}
              </p>
            </div>

            {type === ResetPasswordModalTitleType.profile && (
              <>
                <p className="reset_password_form__body__field_title">
                  {t('misc.current_password')}
                </p>
                <p className="reset_password_form__body__field_subtitle">
                  {t('auth.just_safe')}
                </p>

                <FormikInputField
                  type="password"
                  name="currentPassword"
                  handleBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  className="current-password"
                  autocomplete="current-password"
                  placeholder={`${t('misc.current_password')}...`}
                  showErrorWhenInFocus
                  errors={
                    touched.currentPassword
                      ? errors.currentPassword || commonError
                      : undefined
                  }
                />
              </>
            )}

            <p className="reset_password_form__body__field_title">
              {t('misc.new_password')}
            </p>

            <FormikInputField
              type="password"
              name="newPassword"
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              showErrorWhenInFocus
              className="new-password"
              autocomplete="new-password"
              placeholder={`${t('misc.new_password')}...`}
              withBulletsRequiredList={
                !isEmpty(errors.newPassword) && !!touched.newPassword
              }
              bulletsRequiredChecksList={passwordChecksList}
              bulletsRequiredTitle={t('misc.your_password_needs')}
              errors={touched.newPassword ? errors.newPassword : undefined}
            />

            <FormikInputField
              type="password"
              name="newPasswordConfirmation"
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              className="password-confirmation"
              autocomplete="new-password"
              placeholder={`${t('misc.repeat_new_password')}...`}
              errors={
                touched.newPasswordConfirmation
                  ? errors.newPasswordConfirmation
                  : undefined
              }
            />
          </div>

          {type === ResetPasswordModalTitleType.profile ? (
            <div className="reset_password_form__profile_footer">
              <Button
                type="btn"
                btnType="button"
                btnLook="filled-gray"
                value={t('misc.cancel')}
                onClick={onResetPasswordModalCloseClick}
                size="large"
              />

              <Button
                type="btn"
                btnLook="filled"
                btnType="submit"
                rightIcon={
                  <CgArrowRightO
                    size={20}
                    color={
                      tenantStyle?.colorVariables.icons.icon_primary2_button
                    }
                  />
                }
                value={t('auth.change_password')}
                size="large"
                disabled={isEmpty(touched) || !isEmpty(errors)}
              />
            </div>
          ) : (
            <div className="reset_password_form__footer">
              <Button
                type="btn"
                btnLook="filled"
                btnType="submit"
                rightIcon={
                  <CgArrowRightO
                    size={20}
                    color={
                      tenantStyle?.colorVariables.icons.icon_primary2_button
                    }
                  />
                }
                value={t('auth.change_password')}
                size="large"
                disabled={isEmpty(touched) || !isEmpty(errors)}
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default memo(ResetPasswordForm);
