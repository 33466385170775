import { useCallback, useEffect, useState } from 'react';

export const getBrowserVisibilityProp = () => {
  const doc = document as any;

  if (typeof doc.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  }

  if (typeof doc.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  }

  if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }

  return 'visibilitychange';
};

export const getBrowserDocumentHiddenProp = () => {
  const doc = document as any;

  if (typeof doc.hidden !== 'undefined') {
    return 'hidden';
  }

  if (typeof doc.msHidden !== 'undefined') {
    return 'msHidden';
  }

  if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }

  return 'hidden';
};

export const getIsDocumentHidden = () => {
  const hiddenProp = getBrowserDocumentHiddenProp();
  return !document[hiddenProp];
};

export const useIsPageTabInFocus = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());

  const onVisibilityChange = useCallback(() => {
    setIsVisible(getIsDocumentHidden());
  }, [setIsVisible]);

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return isVisible;
};
