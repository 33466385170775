import { User, multiFactor } from '@firebase/auth';

export const getFirebaseUserMFAPhoneNumber = (
  firebaseUser: User | null,
): string | null => {
  if (!firebaseUser) {
    return null;
  }

  const enrolledFactor: any = multiFactor(firebaseUser)?.enrolledFactors?.[0];

  return enrolledFactor?.phoneNumber || null;
};
