import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SessionInfoType } from '_types/sessions.interface';
import type {
  InfoModalType,
  ShowResetPasswordModalType,
  GeneralModalType,
  NonDigitalFundingInfoModalType,
  LoginModalType,
  ProfileMissingDataModalType,
  ProfileMissingDataSuccessModal,
  ExternalUrlConsentModalType,
  FundingCardExpandedViewModalType,
} from '_types/modals.interface';

interface ModalState {
  showLoginModal: LoginModalType | null;
  showResetPasswordModal: ShowResetPasswordModalType | null;
  showResetEmailModal: boolean;
  showRateSessionModal: SessionInfoType | null;
  showInfoModal: InfoModalType | null;
  showGeneralModal: GeneralModalType | null;
  showNonDigitalFundingInfoModal: NonDigitalFundingInfoModalType | null;
  showProfileMissingDataModal: ProfileMissingDataModalType | null;
  showProfileMissingDataSuccessModal: ProfileMissingDataSuccessModal | null;
  showExternalUrlConsentModal: ExternalUrlConsentModalType | null;
  showFundingCardExpandedViewModal: FundingCardExpandedViewModalType | null;
  showMenuModal: boolean;
  showCalculatorModal: boolean;
}

export const modalSliceInitialState: ModalState = {
  showLoginModal: null,
  showResetPasswordModal: null,
  showResetEmailModal: false,
  showRateSessionModal: null,
  showInfoModal: null,
  showGeneralModal: null,
  showNonDigitalFundingInfoModal: null,
  showProfileMissingDataModal: null,
  showProfileMissingDataSuccessModal: null,
  showExternalUrlConsentModal: null,
  showFundingCardExpandedViewModal: null,
  showMenuModal: false,
  showCalculatorModal: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalSliceInitialState,
  reducers: {
    resetState: () => modalSliceInitialState,
    actionHandleShowLoginModal(
      state,
      action: PayloadAction<LoginModalType | null>,
    ) {
      state.showLoginModal = action.payload;
    },
    actionHandleShowResetPasswordModal(
      state,
      action: PayloadAction<ShowResetPasswordModalType | null>,
    ) {
      state.showResetPasswordModal = action.payload;
    },
    actionHandleShowResetEmailModal(state, action: PayloadAction<boolean>) {
      state.showResetEmailModal = action.payload;
    },
    actionHandleShowRateSessionModal(
      state,
      action: PayloadAction<SessionInfoType | null>,
    ) {
      state.showRateSessionModal = action.payload;
    },
    actionHandleShowInfoModal(
      state,
      action: PayloadAction<InfoModalType | null>,
    ) {
      state.showInfoModal = action.payload;
    },
    actionHandleShowGeneralModal(
      state,
      action: PayloadAction<GeneralModalType | null>,
    ) {
      state.showGeneralModal = action.payload;
    },
    actionHandleShowNonDigitalFundingInfoModal(
      state,
      action: PayloadAction<NonDigitalFundingInfoModalType | null>,
    ) {
      state.showNonDigitalFundingInfoModal = action.payload;
    },
    actionHandleShowProfileMissingDataModal(
      state,
      action: PayloadAction<ProfileMissingDataModalType | null>,
    ) {
      state.showProfileMissingDataModal = action.payload;
    },
    actionHandleShowProfileMissingDataSuccessModal(
      state,
      action: PayloadAction<ProfileMissingDataSuccessModal | null>,
    ) {
      state.showProfileMissingDataSuccessModal = action.payload;
    },
    actionHandleShowExternalUrlConsentModal(
      state,
      action: PayloadAction<ExternalUrlConsentModalType | null>,
    ) {
      state.showExternalUrlConsentModal = action.payload;
    },
    actionHandleShowFundingExpandedViewModal(
      state,
      action: PayloadAction<FundingCardExpandedViewModalType | null>,
    ) {
      state.showFundingCardExpandedViewModal = action.payload;
    },
    actionHandleShowMenuModal(state, action: PayloadAction<boolean>) {
      state.showMenuModal = action.payload;
    },
    actionHandleShowCalculatorModal(state, action: PayloadAction<boolean>) {
      state.showCalculatorModal = action.payload;
    },
  },
});

export const {
  actionHandleShowLoginModal,
  actionHandleShowResetPasswordModal,
  actionHandleShowResetEmailModal,
  actionHandleShowRateSessionModal,
  actionHandleShowInfoModal,
  actionHandleShowGeneralModal,
  actionHandleShowNonDigitalFundingInfoModal,
  actionHandleShowProfileMissingDataModal,
  actionHandleShowProfileMissingDataSuccessModal,
  actionHandleShowExternalUrlConsentModal,
  actionHandleShowFundingExpandedViewModal,
  actionHandleShowMenuModal,
  actionHandleShowCalculatorModal,
  resetState,
} = modalSlice.actions;

export default modalSlice;
