import React, { memo, useMemo } from 'react';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import NotificationBlock from '../NotificationBlock';

import { getContentBlock, getTitleBlock } from './utils';
import { RateSessionNotificationNotificationObject } from './types';

interface RateSessionNotificationProps {
  notification: RateSessionNotificationNotificationObject;
}

const RateSessionNotification: React.FC<RateSessionNotificationProps> = ({
  notification,
}) => {
  const { t } = useTenantTranslation();

  const titleBlock = useMemo(
    () => getTitleBlock(notification.data.userMentioned, t),
    [notification.data.userMentioned, t],
  );

  const contentBlock = useMemo(
    () => getContentBlock(notification.data.session, t),
    [notification.data.session, t],
  );

  return (
    <NotificationBlock
      notificationId={notification.id}
      isRead={notification.isRead}
      createdAtUnix={notification.createdAtUnix}
      logoUser={notification.data.userMentioned}
      titleBlock={titleBlock}
      contentBlock={contentBlock}
    />
  );
};

export default memo(RateSessionNotification);
