import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { useTimeAgoTranslated } from 'utils/hooks/useTimeAgoTranslated';

import { NotificationUserMentioned } from '../../types';

import { LinkToObjectType } from './types';
import { getNotificationLogoHref } from './utils';

import './style.scss';

interface NotificationBlockProps {
  notificationId: number;
  isRead: boolean;
  createdAtUnix: number;
  blockClassName?: string;
  logoUser?: NotificationUserMentioned;
  titleBlock?: ReactElement | null;
  contentBlock?: ReactElement | null;
  linkToObject?: LinkToObjectType | null;
}

const NotificationBlock: React.FC<NotificationBlockProps> = ({
  notificationId,
  isRead,
  createdAtUnix,
  blockClassName,
  logoUser,
  titleBlock,
  contentBlock,
  linkToObject,
}) => {
  const { t } = useTenantTranslation();

  const timeAgoTranslated = useTimeAgoTranslated(createdAtUnix, t);

  const linkToObjectLastPart =
    linkToObject && linkToObject.parts[linkToObject.parts.length - 1];

  return (
    <div
      className={clsx('notification_block', blockClassName)}
      data-id={notificationId}
    >
      {!isRead && <div className="red_circle" />}

      {logoUser && (
        <Link
          to={getNotificationLogoHref(logoUser)}
          className="notification_logo_container"
        >
          <AvatarCircle
            firstName={logoUser.firstName}
            lastName={logoUser.lastName}
            src={logoUser.photo}
            size={{ small: '32', large: '32' }}
          />
        </Link>
      )}

      <div className="content">
        {titleBlock && <p className="title">{titleBlock}</p>}

        {contentBlock}

        <p className="time">
          {linkToObject && (
            <>
              <span className="session_link">
                {linkToObject.startIcon}
                {linkToObject.parts
                  .map((part) => (
                    <Link key={part.link} to={part.link}>
                      {part.content}
                    </Link>
                  ))
                  .reduce((prev, curr) => (
                    <>
                      {prev}
                      <span>&#x3e;</span>
                      {curr}
                    </>
                  ))}
              </span>
              <span className="session_link phone">
                <Link to={linkToObjectLastPart!.link}>
                  {linkToObjectLastPart!.content}
                </Link>
              </span>
              •&nbsp;
            </>
          )}
          {timeAgoTranslated}
        </p>
      </div>
    </div>
  );
};

export default NotificationBlock;
