import React, { ReactNode, memo, useMemo, useRef } from 'react';
import { RecaptchaVerifier } from '@firebase/auth';

import RecaptchaContainerContext, { RecaptchaContextInterface } from '.';

interface SocketProviderInterface {
  children: ReactNode;
}

const RecaptchaContainerProvider: React.FC<SocketProviderInterface> = ({
  children,
}) => {
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  const contextValue = useMemo(
    () =>
      ({
        recaptchaContainerRef,
        recaptchaVerifierRef,
      }) as RecaptchaContextInterface,
    [],
  );

  return (
    <RecaptchaContainerContext.Provider value={contextValue}>
      {children}

      <div ref={recaptchaContainerRef}>
        <div id="recaptcha-container-id" />
      </div>
    </RecaptchaContainerContext.Provider>
  );
};

export default memo(RecaptchaContainerProvider);
