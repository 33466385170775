import React, { memo, useCallback } from 'react';

import HomePageTabs from 'app/components/HomePageTabs/HomePageTabs';

import { useAppDispatch } from 'store/configureStore';

import { actionHandleShowMenuModal } from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';

const MenuModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const classNames = {
    modalContainer: 'custom_modal_container_menu',
    modal: 'custom_modal',
    modalPhoneUpperLine: 'custom_upper_line',
    modalInnerContainer: 'custom_inner_container',
  };

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
  }, [dispatch]);

  return (
    <BaseModal
      classNames={classNames}
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
    >
      <HomePageTabs inModal />
    </BaseModal>
  );
};

export default memo(MenuModal);
