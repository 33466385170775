import React, { useMemo, ReactNode, useCallback, memo } from 'react';
import clsx from 'clsx';

import { ProfileFieldErrorType } from '_types/profile.interface';

import CheckedIcon from 'app/components/Icons/CheckedIcon';
import ErrorCloud from 'app/components/ErrorCloud';

import './style.scss';

interface SelectButtonSimpleProps {
  title: string;
  value: string;
  disabled?: boolean;
  selected: boolean;
  type: 'checkbox' | 'select';
  onClick: Function;
  rightDownIcon?: ReactNode;
  leftDownIcon?: ReactNode;
  errors?: ProfileFieldErrorType;
  fieldAligment?: 'left' | 'right';
}

const SelectButtonSimple: React.FC<SelectButtonSimpleProps> = ({
  rightDownIcon,
  title,
  value,
  disabled,
  selected,
  type,
  leftDownIcon,
  onClick,
  errors,
  fieldAligment = 'left',
}) => {
  const fieldToClickOn = useMemo(() => {
    switch (type) {
      case 'select':
        return (
          <div
            className={clsx('select_button__circle', { active: selected })}
          />
        );
      case 'checkbox':
        return (
          <div className={clsx('select_button__box', { active: selected })}>
            {selected && <CheckedIcon className="select_button__box_icon" />}
          </div>
        );
      default:
        return <></>;
    }
  }, [selected, type]);

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick(!selected, value);
  }, [onClick, selected, disabled, value]);

  return (
    <div
      className={clsx('select_button', {
        active: selected,
        disabled,
        error: errors?.length,
      })}
      onClick={handleClick}
    >
      <div className="select_button__block">
        {fieldAligment === 'left' && fieldToClickOn}

        {leftDownIcon && (
          <div className="select_button__icon left">{leftDownIcon}</div>
        )}

        <div className="select_button__text">{title}</div>
      </div>

      {!!errors?.length && <ErrorCloud errorsListOrErrorText={errors} />}

      {fieldAligment === 'right' && fieldToClickOn}

      {rightDownIcon && (
        <div className="select_button__icon">{rightDownIcon}</div>
      )}
    </div>
  );
};

export default memo(SelectButtonSimple);
