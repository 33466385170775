import React, { memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MdOutlineInfo } from 'react-icons/md';

import { phoneConstant } from '_constants/otherConstants';

import {
  FundingReferralType,
  GrantItemType,
  LoanItemType,
} from '_types/funding.interface';

import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import LoanAvatarIcon from 'app/components/Icons/LoanAvatarIcon';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showFundingExpandedViewModalSelector } from 'store/selectors/modal';
import {
  actionHandleShowExternalUrlConsentModal,
  actionHandleShowFundingExpandedViewModal,
  actionHandleShowNonDigitalFundingInfoModal,
} from 'store/reducers/modal';
import {
  profileIdSelector,
  profileSboInfoSelector,
} from 'store/selectors/profile';
import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { getButtonProp } from 'utils/funding';
import useTenantColors from 'utils/hooks/useTenantColors';
import { useCurrentPlatformLanguage } from 'utils/translations';

import {
  MailchimpEventEnum,
  MailChimpTrackEventData,
} from '_types/mailChimp.interface';
import mailchimpService from '_services/mailchimp';
import BaseModal from '../BaseModal';

import GrantCardExpandedView from './GrantCardExpandedView';
import LoanCardExpandedView from './LoanCardExpandedView';

import './style.scss';

const FundingCardExpandedViewModal: React.FC = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();
  const dispatch = useAppDispatch();
  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const currentLanguage = useCurrentPlatformLanguage();
  const funding = useAppSelector(showFundingExpandedViewModalSelector);
  const userId = useAppSelector(profileIdSelector);
  const profileInfo = useAppSelector(profileSboInfoSelector);
  const isIdEven = userId ? userId % 2 === 0 : false;
  const fundingType = funding?.fundingType;
  let fundingValue: GrantItemType | LoanItemType;

  if (fundingType === 'Grant') {
    fundingValue = funding?.fundingValue as unknown as GrantItemType;
  } else {
    fundingValue = funding?.fundingValue as unknown as LoanItemType;
  }

  const buttonProp = useMemo(
    () => getButtonProp(fundingValue?.referralMethod),
    [fundingValue?.referralMethod],
  );
  const loanStyle = {
    backgroundColor: fundingValue.mainColor,
    color: fundingValue.textColor,
  };

  const classNames = {
    modalContainer: 'custom_modal_container',
    modal: 'custom_modal',
    modalInnerContainer: 'custom_modal_inner_container',
    modalHeader: 'custom_modal_header',
    modalTitle: 'custom_modal_title',
    modalContent: 'custom_modal_content',
    modalFooter: fundingValue?.websiteURL
      ? 'custom_modal_footer'
      : 'custom_modal_footer_center',
    acceptButton: 'custom_modal_acceptButton',
    declineButton: 'custom_modal_declinedButton ',
  };

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowFundingExpandedViewModal(null));
  }, [dispatch]);

  const sendCustomEventToGTag = (eventName: string) => {
    const { location } = window;
    const lenderName =
      fundingType === 'Grant'
        ? (fundingValue as GrantItemType)?.grantorName
        : (fundingValue as LoanItemType).lenderName;
    const productName =
      fundingType === 'Grant'
        ? (fundingValue as GrantItemType)?.grantName
        : (fundingValue as LoanItemType)?.loanName;
    window.dataLayer.push({
      event: eventName,
      userId,
      current_url: location.href,
      button_id: `${eventName}_${lenderName}_${productName}_${location.pathname === '/' ? 'mainpage' : 'funding_list'}_sidebar`,
      experiment_assignment: `${eventName.includes('_1') ? 'exp100var1' : 'exp100var2'}`,
    });
  };

  const headerIcon = useMemo(() => {
    if (!fundingValue.fundingProviderIcon) {
      return (
        <LoanAvatarIcon
          fillColor={colorVariables?.icons.icon_secondary}
          width="80"
          height="80"
        />
      );
    }

    return (
      <AvatarCircle
        src={fundingValue.fundingProviderIcon}
        firstName={
          fundingType === 'Grant'
            ? (fundingValue as GrantItemType).grantorName
            : (fundingValue as LoanItemType).lenderName
        }
        lastName=""
        size={{ small: '80', large: '80' }}
      />
    );
  }, [fundingValue?.fundingProviderIcon]);

  const openExternalResource = useCallback(async () => {
    dispatch(actionHandleShowFundingExpandedViewModal(null));
    sendCustomEventToGTag(isIdEven ? 'funding_apply_2' : 'funding_apply_1');

    if (
      fundingValue.referralMethod &&
      fundingValue.referralMethod !== FundingReferralType.EMAIL
    ) {
      dispatch(
        actionHandleShowExternalUrlConsentModal({
          externalUrl: fundingValue.onlineApplicationURL || '#',
          target: '_blank',
          rel: 'noopener noreferrer',
        }),
      );

      const mailChimpEventPayload: MailChimpTrackEventData = {
        eventName: MailchimpEventEnum.VIEWED_CAPITAL_PRODUCT,
        options: {
          firstName: profileInfo.firstName as string,
          lastName: profileInfo.lastName as string,
          email: profileInfo.email as string,
          capitalProductName: t(
            (fundingType === 'Grant'
              ? (fundingValue as GrantItemType)?.grantName?.en
              : (fundingValue as LoanItemType).loanName?.en) || '',
          ),
          website: fundingValue.onlineApplicationURL,
        },
      };

      await mailchimpService.trackEvent(mailChimpEventPayload);
    } else {
      dispatch(
        actionHandleShowNonDigitalFundingInfoModal({
          loanName:
            (fundingType === 'Grant'
              ? (fundingValue as GrantItemType)?.grantName[currentLanguage]
              : (fundingValue as LoanItemType)?.loanName[currentLanguage]) ||
            '',
          mainColor: fundingValue?.mainColor,
          description: null,
          onlineApplicationURL: fundingValue?.websiteURL || '#',
          phone: (fundingValue as LoanItemType)?.phone || null,
          email: fundingValue?.email || null,
          headerIcon,
        }),
      );
    }
  }, []);

  const openExternalWebsite = useCallback(() => {
    dispatch(actionHandleShowFundingExpandedViewModal(null));
    dispatch(
      actionHandleShowExternalUrlConsentModal({
        externalUrl: fundingValue.websiteURL || '#',
        target: '_blank',
        rel: 'noopener noreferrer',
        title: 'Visit Website',
        acceptButtonText: 'Continue',
      }),
    );
  }, []);

  return (
    <BaseModal
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
      backgroundScrollDisabled
      headerIcon={headerIcon}
      modalTitle={t('funding.about_funding_product', {
        productName:
          fundingType === 'Grant'
            ? (fundingValue as GrantItemType)?.grantorName
            : (fundingValue as LoanItemType)?.lenderName,
      })}
      modalDescription={fundingValue?.description[currentLanguage]}
      classNames={classNames}
      withAcceptButton
      acceptButtonSize={isPhone ? 'medium' : 'large'}
      acceptButtonText={t(buttonProp.btnText)}
      acceptButtonStyle={loanStyle}
      onAcceptButtonClick={openExternalResource}
      withDeclineButton={(fundingValue as any)?.websiteURL}
      declineButtonText={t('misc.visit_website')}
      onDeclineButtonClick={openExternalWebsite}
      declineButtonSize={isPhone ? 'medium' : 'large'}
      declineButtonLook="filled-gray"
      declineButtonRightIcon={
        <MdOutlineInfo
          size={24}
          color={colorVariables?.icons.icon_secondary_tetriary_button}
        />
      }
    >
      {fundingType === 'Grant' ? (
        <GrantCardExpandedView />
      ) : (
        <LoanCardExpandedView />
      )}
    </BaseModal>
  );
};

export default memo(FundingCardExpandedViewModal);
