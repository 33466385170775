import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAppSelector } from 'store/configureStore';

import { profileInfoIsUserCookieConsentSelector } from 'store/selectors/profile';

import { useValueFromLocalStorage } from 'utils/hooks/useValueFromLocalStorage';

const analyticsKey = process.env.REACT_APP_ANALYTICS_KEY;

const GoogleTagManagerScript: React.FC = () => {
  const cookieConsent = useValueFromLocalStorage('nextStreetCookiePolicy');

  const profileInfoIsUserCookieConsent = useAppSelector(
    profileInfoIsUserCookieConsentSelector,
  );

  const shouldInsertGoogleTagScript =
    cookieConsent !== 'Declined' || profileInfoIsUserCookieConsent;

  if (shouldInsertGoogleTagScript) {
    return (
      <Helmet>
        <script>
          {`(function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${analyticsKey}');`}
        </script>
      </Helmet>
    );
  }

  return null;
};

export default memo(GoogleTagManagerScript);
