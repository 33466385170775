import React from 'react';

import { SocialLinksObjectType } from '_types/onboarding.interface';

import FacebookBlackIcon from 'app/components/Icons/SocialIcons/BlackIcons/FacebookBlackIcon';
import InstagramBlackIcon from 'app/components/Icons/SocialIcons/BlackIcons/InstagramBlackIcon';
import LinkedinBlackIcon from 'app/components/Icons/SocialIcons/BlackIcons/LinkedinBlackIcon';
import TwitterBlackIcon from 'app/components/Icons/SocialIcons/BlackIcons/TwitterBlackIcon';
import LinkedinIcon from 'app/components/Icons/SocialIcons/ColorIcons/LinkedinIcon';
import TwitterIcon from 'app/components/Icons/SocialIcons/ColorIcons/TwitterIcon';
import InstagramIcon from 'app/components/Icons/SocialIcons/ColorIcons/InstagramIcon';
import FacebookIcon from 'app/components/Icons/SocialIcons/ColorIcons/FacebookIcon';

export const tabletConstant: number = 834;
export const phoneConstant: number = 550;

export const buttonClickEventEscapeButtonNumber = 27;

export const photoSizeMbMax = 5;
export const videoSizeMbMax = 100;

export const nextStreetWordConstant = 'nextstreet';
export const utmCampaignWordConstant = 'utm_campaign';

export const phoneValidationRegExp =
  /(^\s*(?:\+?1)?[ (.-]*(\d{3})[ ).-]*(\d{3})[ .-]*(\d{4})(?: *x(\d+))?\s*$)|(^$)/;

export const zipCodeValidationRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const nameValidationRegExp = /^(?=.{2,}$)[A-Za-z0-9]+$/;

export const isEmailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const einValidationRegExp =
  /^(?!(00|07|08|09|17|18|19|28|29|49|69|70|78|79|89|96|97))[0-9]{2}-[0-9]{7}$/;

export const calendlyUrlValidationRegExp =
  /(^(?:https:\/\/)?calendly\.com\/[\w!#$&'()*+,./:;=?@[\]~-]+$)/;

export const allowedPhotoExtensions = ['image/jpeg', 'image/png', 'image/jpg'];

export const allowedVideoExtensions = [
  'video/webm',
  'video/mpg',
  'video/mp2',
  'video/mpeg',
  'video/mpe',
  'video/mpv',
  'video/ogg',
  'video/mp4',
  'video/m4p',
  'video/m4v',
  'video/avi',
  'video/wmv',
  'video/mov',
  'video/qt',
  'video/quicktime',
  'video/flv',
  ' video/swf',
  'video/avchd',
];

export const socialLinksList: SocialLinksObjectType[] = [
  {
    name: 'Linkedin',
    value: 'linkedIn',
    icon: <LinkedinBlackIcon />,
    iconColor: <LinkedinIcon />,
  },
  {
    name: 'Twitter',
    value: 'twitter',
    icon: <TwitterBlackIcon />,
    iconColor: <TwitterIcon />,
  },
  {
    name: 'Instagram',
    value: 'instagram',
    icon: <InstagramBlackIcon />,
    iconColor: <InstagramIcon />,
  },
  {
    name: 'Facebook',
    value: 'facebook',
    icon: <FacebookBlackIcon />,
    iconColor: <FacebookIcon />,
  },
];

export const defaultTimezone = 'America/New_York';

export enum CancelerTypeEnum {
  HOST = 'host',
  INVITEE = 'invitee',
}

export enum CalendlyEventLocationTypeEnum {
  ZOOM = 'zoom',
  OUTBOUND_CALL = 'outbound_call',
}

export enum SessionStatusEnum {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  RESCHEDULED = 'rescheduled',
}

export enum DescriptionTypesEnum {
  LOGIN_INFO = '',
  PROFILE = '',
}
