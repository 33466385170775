import type { SboAsClient } from '_types/sbo.interface';

import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const clientService = {
  getClientInfo: (id: number) =>
    axiosApiInstance
      .get<SboAsClient>(`${baseUrl}/api/v1/sbo/profile/${id}`)
      .then(({ data }) => data),
};

export default clientService;
