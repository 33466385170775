import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

import { ProfileFieldErrorType } from '_types/profile.interface';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import './style.scss';

interface BulletsRequiredListProps<T = ProfileFieldErrorType> {
  errorsContainerTitle?: string;
  checksList: T[];
  errorsList?: T | T[];
  className?: string;
}

const BulletsRequiredList: React.FC<BulletsRequiredListProps> = ({
  errorsContainerTitle,
  checksList,
  errorsList,
  className,
}) => {
  const tenantStyle = useAppSelector(tenantSettingsTenantStylingSelector);
  const formattedErrorList = useMemo(() => {
    if (!errorsList) {
      return [];
    }

    if (typeof errorsList === 'string') {
      return [errorsList];
    }

    return errorsList;
  }, [errorsList]);

  const outOfCheckListErrors = useMemo(
    () => formattedErrorList?.filter((error) => !checksList.includes(error)),
    [formattedErrorList, checksList],
  );

  const errorsListComponent = useMemo(
    () =>
      checksList?.map((errorTextToCheck, index) => {
        const hasError = formattedErrorList?.includes(errorTextToCheck);

        return (
          <div key={index} className="bullets_required__list_item">
            {hasError ? (
              <MdCancel
                className="icon"
                color={tenantStyle?.colorVariables.icons.icon_secondary}
                style={{ opacity: 0.5 }}
              />
            ) : (
              <FaCheckCircle
                className="icon"
                color={
                  tenantStyle?.colorVariables.buttons
                    .button_radio_button_check_box
                }
              />
            )}

            <p>{errorTextToCheck}</p>
          </div>
        );
      }),
    [checksList, formattedErrorList],
  );

  return (
    <div className={clsx('bullets_required', className)}>
      <div className="bullets_required__triangle">
        <div />
        <div />
        <div />
      </div>

      {outOfCheckListErrors?.length ? (
        <p className="bullets_required__text additional-error">
          {outOfCheckListErrors[0]}
        </p>
      ) : null}

      <p className="bullets_required__text">{errorsContainerTitle}</p>

      <div className="bullets_required__list">{errorsListComponent}</div>
    </div>
  );
};

export default memo(BulletsRequiredList);
