import React, { memo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsFavIconSelector } from 'store/selectors/tenant-settings';

import {
  attributeConstructor,
  removeExistingElement,
} from 'utils/constructHtmlElement';

interface PageDataAttributesProps {
  pageTitle?: string;
  pageMetaData?: object;
}

const PageHelmet: React.FC<PageDataAttributesProps> = ({
  pageTitle,
  pageMetaData,
}) => {
  const favIconLink = useAppSelector(tenantSettingsFavIconSelector);

  const getFaviconElement = useCallback(() => {
    removeExistingElement('link', 'rel', 'icon');
    return <link rel="icon" href={favIconLink} />;
  }, []);

  return (
    <Helmet>
      {pageTitle && <title>{pageTitle}</title>}
      {favIconLink && getFaviconElement()}
      {pageMetaData &&
        Object.keys(pageMetaData)?.map((tagName) => {
          const TagComponent = tagName?.toLocaleLowerCase();
          return (
            Array.isArray(pageMetaData[tagName]) &&
            pageMetaData[tagName]?.map((data: any, index: number) => {
              const attributes = attributeConstructor(data);
              Object.keys(data)?.map((attributeName) =>
                removeExistingElement(
                  TagComponent,
                  attributeName,
                  data[attributeName],
                ),
              );
              return <TagComponent key={index} {...attributes} />;
            })
          );
        })}
      ;
    </Helmet>
  );
};

export default memo(PageHelmet);
