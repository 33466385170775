import React, { memo } from 'react';

import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';

import useTenantColors from 'utils/hooks/useTenantColors';

interface ModalHeaderProps {
  photo?: string;
  firstName: string;
  lastName: string;
  company?: string;
  bio?: string;
}
const BookingModalHeader: React.FC<ModalHeaderProps> = ({
  photo,
  firstName,
  lastName,
  company,
  bio,
}) => {
  const { colorVariables } = useTenantColors();

  return (
    <div className="modal_header">
      <div className="modal_header_user">
        <AvatarCircle
          className="modal_header_user_avatar"
          firstName={firstName}
          lastName={lastName}
          src={photo}
          size={{ small: '48', large: '48' }}
          color={colorVariables?.background.background_avatar_with_letters}
        />
        <div className="modal_header_user_info">
          {(!!firstName || !!lastName) && (
            <p className="modal_header_user_info_name">{`${[firstName, lastName].join(' ')}`}</p>
          )}
          {!!company && (
            <p className="modal_header_user_info_company">{company}</p>
          )}
        </div>
      </div>
      {!!bio && <p className="modal_header_description">{bio}</p>}
    </div>
  );
};

export default memo(BookingModalHeader);
