import React, { ReactNode, memo, useMemo, useState } from 'react';

import { SocketDataInterface } from '_types/socket.interface';

import SocketContext from 'utils/contexts/SocketContext';

interface SocketProviderInterface {
  children: ReactNode;
}

const SocketProvider: React.FC<SocketProviderInterface> = ({ children }) => {
  const [socketData, setSocketData] = useState<SocketDataInterface>({
    socket: null,
    saveMessageOnSocketNotConnected: () => {},
  });

  const socketProviderData = useMemo(
    () => ({ ...socketData, setSocketData }),
    [socketData, setSocketData],
  );

  return (
    <SocketContext.Provider value={socketProviderData}>
      {children}
    </SocketContext.Provider>
  );
};

export default memo(SocketProvider);
