import React, { memo, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { ProfileSBOInfo } from '_types/profile.interface';

import { MySboProfileErrorsType } from 'app/pages/MyAccount/components/MyProfile/MySboProfile/validationSchemas';
import SboIndustrySelectComponent from 'app/components/ProfileFields/sbo/SboIndustrySelectComponent';
import SboNYCBoroughSelectComponent, {
  NYCBoroughComponentValue,
} from 'app/components/ProfileFields/sbo/SboNYCBoroughSelectComponent';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showProfileMissingDataModalSelector } from 'store/selectors/modal';
import {
  profileErrorsSelector,
  profileInfoSelector,
} from 'store/selectors/profile';
import {
  actionChangeProfileMissingDataValidationErrors,
  actionEditSboInfo,
} from 'store/reducers/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import './styles.scss';

const SboMissingDataForm: React.FC = () => {
  const errors = useAppSelector(profileErrorsSelector<MySboProfileErrorsType>);
  const showProfileMissingDataModal = useAppSelector(
    showProfileMissingDataModalSelector,
  );

  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const profileInfo = useAppSelector(profileInfoSelector);

  const onChangeSboInfo = useCallback(
    (key: keyof ProfileSBOInfo) => (value: unknown) => {
      dispatch(actionEditSboInfo({ [key]: value }));

      if (errors.sboData) {
        const newErrors = cloneDeep(errors);
        dispatch(actionChangeProfileMissingDataValidationErrors(newErrors));
      }
    },
    [dispatch],
  );

  const onNYCBoroughChange = useCallback(
    ({ NYCBorough }: NYCBoroughComponentValue) =>
      onChangeSboInfo('NYCBorough')(NYCBorough),
    [onChangeSboInfo],
  );

  const findMissingField = useCallback(
    (field: string) =>
      showProfileMissingDataModal?.missingFields.find(
        (value) => value === field,
      ),
    [],
  );

  return (
    <>
      {findMissingField('sboData.industry') && (
        <SboIndustrySelectComponent
          onChange={onChangeSboInfo('industry')}
          errors={errors.sboData?.industry}
          value={profileInfo?.sboData?.industry}
          subTitle={t('onboarding.industry_sub_title')}
        />
      )}

      {findMissingField('sboData.NYCBorough') && (
        <SboNYCBoroughSelectComponent
          value={{
            businessState: profileInfo?.sboData?.businessState,
            NYCBorough: profileInfo?.sboData?.NYCBorough,
          }}
          onChange={onNYCBoroughChange}
          errors={{ NYCBorough: errors.sboData?.NYCBorough }}
        />
      )}
    </>
  );
};

export default memo(SboMissingDataForm);
