import { TenantSettings } from '_types/tenant-settings';

import {
  PageConfigurationTypeEnum,
  TenantPageConfigurationEntity,
} from '_types/tenant-settings/tenant-page-configuration';
import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const tenantSettingsService = {
  getTenantSettings: () =>
    axiosApiInstance
      .get<TenantSettings>(`${baseUrl}/api/v1/tenant-settings`)
      .then(({ data }) => data),

  getTenantPageConfiguration: (type: PageConfigurationTypeEnum) =>
    axiosApiInstance
      .get<TenantPageConfigurationEntity>(
        `${baseUrl}/api/v1/tenant-settings/tenant-page-configuration/${type}`,
      )
      .then(({ data }) => data),
};

export default tenantSettingsService;
