import { RootState } from 'store/configureStore';

export const isOnboardingDataPresentSelector = (state: RootState) =>
  !!state.onboarding.onboardingData && !!state.onboarding.onboardingPagesData;
export const onboardingPagesDataSelector = (state: RootState) =>
  state.onboarding.onboardingPagesData;
export const onboardingDataSelector = (state: RootState) =>
  state.onboarding.onboardingData;
export const isOnboardingProgressPresentSelector = (state: RootState) =>
  !!state.onboarding.onboardingProgress;
export const onboardingProgressSelector = (state: RootState) =>
  state.onboarding.onboardingProgress;
export const onboardingStepSelector = (state: RootState) =>
  state.onboarding.onboardingProgress!.onboardingStep;
export const isOnboardingCompletedSelector = (state: RootState) =>
  state.onboarding.onboardingProgress?.isOnboardingCompleted;

export const onboardingDataLoaderSelector = (state: RootState) =>
  state.onboarding.dataLoader;
export const onboardingPagesLoaderSelector = (state: RootState) =>
  state.onboarding.pagesLoader;
export const onboardingRequestErrorsSelector = (state: RootState) =>
  state.onboarding.requestErrors;
export const onboardingQuestionsIndustryConfigurationSelector = (
  state: RootState,
) => state.onboarding.onboardingQuestions.industry?.options;
