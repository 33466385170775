import type {
  AdvisoryOrgDetails,
  AdvisoryOrgFilterSettings,
  AdvisoryOrgSearchType,
} from '_types/advisory-org.interface';

import { TenantFilterTypeEnum } from '_types';
import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const advisoryOrgService = {
  getAdvisoryOrgDetails: (id: number) =>
    axiosApiInstance
      .get<AdvisoryOrgDetails>(`${baseUrl}/api/v1/advisory-org/details/${id}`)
      .then(({ data }) => data),
  getAllAdvisoryOrgNames: () =>
    axiosApiInstance
      .get<string[]>(`${baseUrl}/api/v1/advisory-org/org-names`)
      .then(({ data }) => data),
  getAdvisoryOrgList: (body, offset = 0, limit = 20) =>
    axiosApiInstance
      .post(
        `${baseUrl}/api/v1/advisory-org/search-advisor-org?offset=${offset}&limit=${limit}`,
        body,
      )
      .then(({ data }) => data),
  getAdvisoryOrgFilterSettings: () =>
    axiosApiInstance
      .get<AdvisoryOrgFilterSettings>(
        `${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.ADVISOR_ORG_FILTER}`,
      )
      .then(({ data }) => data),
};

export default advisoryOrgService;
