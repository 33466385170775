import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  UpdateOnboardingData,
  UpdateOnboardingProgress,
} from '_types/onboarding.interface';

import OnboardingService from '_services/onboardingService';

export const fetchGetOnboardingPages = createAsyncThunk(
  'onboarding/fetchGetOnboardingPages',
  async (_, { rejectWithValue }) => {
    try {
      const onboardingPages = await OnboardingService.getOnboardingPages();

      return onboardingPages;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetOnboardingProgress = createAsyncThunk(
  'onboarding/fetchGetOnboardingProgress',
  async (_, { rejectWithValue }) => {
    try {
      const onboardingProgress =
        await OnboardingService.getOnboardingProgress();

      return onboardingProgress;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPutOnboardingProgress = createAsyncThunk(
  'onboarding/fetchPutOnboardingProgress',
  async (onboardingProgress: UpdateOnboardingProgress, { rejectWithValue }) => {
    try {
      const updatedOnboardingProgress =
        await OnboardingService.putOnboardingProgress(onboardingProgress);

      return updatedOnboardingProgress;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetOnboardingData = createAsyncThunk(
  'onboarding/fetchGetOnboardingData',
  async (_, { rejectWithValue }) => {
    try {
      const onboardingData = await OnboardingService.getOnboardingData();

      return onboardingData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPutOnboardingData = createAsyncThunk(
  'onboarding/fetchPutOnboardingData',
  async (onboardingData: UpdateOnboardingData, { rejectWithValue }) => {
    try {
      const updatedOnboardingData =
        await OnboardingService.putOnboardingData(onboardingData);

      return updatedOnboardingData;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetOnboardingQuestionConfigurationForIndustry =
  createAsyncThunk(
    'onboarding/fetchGetOnboardingQuestionConfigurationForIndustry',
    async (_, { rejectWithValue }) => {
      try {
        const onboardingQuestion =
          await OnboardingService.getOnboardingQuestionConfigurationForIndustry();

        return onboardingQuestion;
      } catch (error: any) {
        return rejectWithValue(error);
      }
    },
  );
