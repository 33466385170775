import {
  PaginatedPartnerResourcesResponseType,
  PartnerResourcesSearchType,
} from '_types/partner-resources.interface';

import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const partnerResourcesService = {
  getPartnerResourcesList: (
    partnerResourcesSearchPayload: PartnerResourcesSearchType,
    partnerResourcesType?: string,
  ) =>
    axiosApiInstance
      .post<PaginatedPartnerResourcesResponseType>(
        `${baseUrl}/api/v1/partner-resources/fetch-partner-resources?type=${partnerResourcesType}`,
        partnerResourcesSearchPayload,
      )
      .then(({ data }) => data),
};
export default partnerResourcesService;
