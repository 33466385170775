import React, { ReactElement } from 'react';

import {
  BackendNotification,
  NotificationTypeEnum,
} from '_types/notifications.interface';

import MentionNotification from './components/MentionNotification';
import SessionCompletedNotification from './components/SessionCompletedNotification';
import SessionDataNotification from './components/SessionDataNotification';
import RateSessionNotification from './components/RateSessionNotification';
import {
  SessionDataNotificationNotificationObject,
  SessionDataNotificationTypeEnum,
} from './components/SessionDataNotification/types';

import type { SessionCompletedNotificationNotificationObject } from './components/SessionCompletedNotification/types';
import type { MentionNotificationNotificationObject } from './components/MentionNotification/types';
import type { RateSessionNotificationNotificationObject } from './components/RateSessionNotification/types';

export const renderNotification = (
  notification: BackendNotification,
): ReactElement | null => {
  let notificationObj;

  switch (notification.type) {
    case NotificationTypeEnum.MENTION:
      notificationObj = notification as MentionNotificationNotificationObject;
      return (
        <MentionNotification
          key={notification.id}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_BOOKED:
      notificationObj =
        notification as SessionDataNotificationNotificationObject;
      return (
        <SessionDataNotification
          key={notification.id}
          sessionNotificationType={SessionDataNotificationTypeEnum.BOOKED}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_SCHEDULED:
      notificationObj =
        notification as SessionDataNotificationNotificationObject;
      return (
        <SessionDataNotification
          key={notification.id}
          sessionNotificationType={SessionDataNotificationTypeEnum.SCHEDULED}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_RESCHEDULED:
      notificationObj =
        notification as SessionDataNotificationNotificationObject;
      return (
        <SessionDataNotification
          key={notification.id}
          sessionNotificationType={SessionDataNotificationTypeEnum.RESCHEDULED}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_CANCELED_TO_SBO:
      notificationObj =
        notification as SessionDataNotificationNotificationObject;
      return (
        <SessionDataNotification
          key={notification.id}
          sessionNotificationType={
            SessionDataNotificationTypeEnum.CANCELED_TO_SBO
          }
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_CANCELED_TO_ADVISOR:
      notificationObj =
        notification as SessionDataNotificationNotificationObject;
      return (
        <SessionDataNotification
          key={notification.id}
          sessionNotificationType={
            SessionDataNotificationTypeEnum.CANCELED_TO_ADVISOR
          }
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.SESSION_COMPLETED:
      notificationObj =
        notification as SessionCompletedNotificationNotificationObject;
      return (
        <SessionCompletedNotification
          key={notification.id}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.RATE_SUGGESTION:
      notificationObj =
        notification as RateSessionNotificationNotificationObject;
      return (
        <RateSessionNotification
          key={notification.id}
          notification={notificationObj}
        />
      );
    case NotificationTypeEnum.PROFILE_ACCESS_REQUESTED:
    case NotificationTypeEnum.PROFILE_ACCESS_GRANTED:
    case NotificationTypeEnum.NOTE_ADDED:
    default:
      // eslint-disable-next-line no-console
      console.error(
        "Couldn't find a way to render notification: ",
        notification,
      );
      return null;
  }
};

export const getFullyVisibleNotificationIds = () => {
  const notificationsContainerElement = document.getElementById(
    'profile-notifications-container',
  );
  const allNotificationBlocks = Array.from(
    document.getElementsByClassName('notification_block'),
  );

  const { top: containerTop, bottom: containerBottom } =
    notificationsContainerElement!.getBoundingClientRect();

  const fullyVisibleNotificationIds = allNotificationBlocks
    .filter((element) => {
      const { top: elementTop, bottom: elementBottom } =
        element.getBoundingClientRect();

      return elementTop > containerTop && elementBottom < containerBottom;
    })
    .map((notificationElement) =>
      parseInt(notificationElement.getAttribute('data-id')!, 10),
    );

  return fullyVisibleNotificationIds;
};
