import StepTimeSelectorComponent from './StepTimeSelectorComponent';
import FinishStepComponent from './FinishStepComponent';

export const calendlySteps = {
  stepTime: 1,
  stepCalendly: 2,
  stepFinal: 4,
};

export const stepComponents = {
  [calendlySteps.stepTime]: StepTimeSelectorComponent,
  [calendlySteps.stepFinal]: FinishStepComponent,
};

export const calendlyStyles = {
  height: '100%',
  width: '100%',
};
