import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TenantSettings } from '_types/tenant-settings';

import { fetchGetTenantSettings } from 'store/actions/tenant-settings';

interface TenantSettingsState {
  tenantSettings: TenantSettings | null;
  isLoading: boolean;
}

export const tenantSettingsSliceInitialState: TenantSettingsState = {
  tenantSettings: null,
  isLoading: true,
};

const tenantSettingsSlice = createSlice({
  name: 'tenant-settings',
  initialState: tenantSettingsSliceInitialState,
  reducers: {
    resetState: () => tenantSettingsSliceInitialState,
    changeTenantSettings(state, action: PayloadAction<TenantSettings>) {
      state.tenantSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetTenantSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetTenantSettings.fulfilled, (state, action) => {
      state.tenantSettings = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGetTenantSettings.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { changeTenantSettings, resetState } = tenantSettingsSlice.actions;

export default tenantSettingsSlice;
