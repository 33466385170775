import { createSlice } from '@reduxjs/toolkit';

import { GroupSessionType, SessionInfoType } from '_types/sessions.interface';

// eslint-disable-next-line import/no-cycle
import {
  fetchGetPastSessions,
  fetchGetUpcomingSessions,
  fetchSessionById,
  fetchGroupAllSessions,
  fetchGroupOpenSessions,
  fetchClientsCounts,
} from 'store/actions/sessions';

export type ReduxSessionsData = {
  isLoading: boolean;
  sessions: SessionInfoType[] | null;
  count: number;
};

type ReduxSessionData = {
  data: SessionInfoType | null;
  notFound: boolean;
};

type ReduxGroupClientsData = {
  isLoading: boolean;
  data: GroupSessionType[] | null;
  count: number;
};

interface SessionsState {
  upcoming: ReduxSessionsData;
  past: ReduxSessionsData;
  session: ReduxSessionData;
  groupAll: ReduxGroupClientsData;
  groupOpen: ReduxGroupClientsData;
}

export const sessionsSliceInitialState: SessionsState = {
  upcoming: {
    isLoading: true,
    sessions: null,
    count: 0,
  },
  past: {
    isLoading: true,
    sessions: null,
    count: 0,
  },
  session: {
    data: null,
    notFound: false,
  },
  groupAll: {
    isLoading: true,
    data: null,
    count: 0,
  },
  groupOpen: {
    isLoading: true,
    data: null,
    count: 0,
  },
};

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: sessionsSliceInitialState,
  reducers: {
    resetState: () => sessionsSliceInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetUpcomingSessions.pending, (state) => {
      state.upcoming.isLoading = true;
    });
    builder.addCase(fetchGetUpcomingSessions.fulfilled, (state, action) => {
      state.upcoming = {
        isLoading: false,
        sessions: action.payload.sessionInfos,
        count: action.payload.count,
      };
    });
    builder.addCase(fetchGetUpcomingSessions.rejected, (state) => {
      state.upcoming.isLoading = false;
    });
    builder.addCase(fetchGetPastSessions.pending, (state) => {
      state.past.isLoading = true;
    });
    builder.addCase(fetchGetPastSessions.fulfilled, (state, action) => {
      state.past = {
        isLoading: false,
        sessions: action.payload.sessionInfos,
        count: action.payload.count,
      };
    });
    builder.addCase(fetchGetPastSessions.rejected, (state) => {
      state.past.isLoading = false;
    });
    builder.addCase(fetchSessionById.pending, (state) => {
      state.session.notFound = false;
      state.session.data = null;
    });
    builder.addCase(fetchSessionById.fulfilled, (state, action) => {
      state.session.notFound = false;
      state.session.data = action.payload;
    });
    builder.addCase(fetchSessionById.rejected, (state) => {
      state.session.notFound = true;
      state.session.data = null;
    });

    builder.addCase(fetchGroupAllSessions.pending, (state) => {
      state.groupAll.isLoading = true;
      state.groupAll.data = null;
    });
    builder.addCase(fetchGroupAllSessions.fulfilled, (state, action) => {
      state.groupAll.isLoading = false;
      state.groupAll.data = action.payload;
    });
    builder.addCase(fetchGroupAllSessions.rejected, (state) => {
      state.groupAll.isLoading = false;
    });

    builder.addCase(fetchGroupOpenSessions.pending, (state) => {
      state.groupOpen.isLoading = true;
      state.groupOpen.data = null;
    });
    builder.addCase(fetchGroupOpenSessions.fulfilled, (state, action) => {
      state.groupOpen.isLoading = false;
      state.groupOpen.data = action.payload;
    });
    builder.addCase(fetchGroupOpenSessions.rejected, (state) => {
      state.groupOpen.isLoading = false;
    });

    builder.addCase(fetchClientsCounts.fulfilled, (state, action) => {
      state.groupOpen.count = action.payload.havingUpcomingSessionsCount;
      state.groupAll.count = action.payload.allCount;
    });
  },
});

export const { resetState } = sessionsSlice.actions;

export default sessionsSlice;
