import React, { memo } from 'react';

interface InstagramBlackIconProps {
  className?: string;
}
const InstagramBlackIcon: React.FC<InstagramBlackIconProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4247 4.116C10.7925 4.0875 10.602 4.0815 9 4.0815C7.398 4.0815 7.20825 4.0875 6.576 4.116C4.9485 4.19025 4.19025 4.96125 4.11675 6.57525C4.0875 7.20825 4.08075 7.398 4.08075 9C4.08075 10.602 4.0875 10.7917 4.116 11.4247C4.19025 13.0357 4.9455 13.8097 6.57525 13.884C7.2075 13.9125 7.398 13.9193 9 13.9193C10.6027 13.9193 10.7925 13.9132 11.4247 13.8848C13.0522 13.8105 13.8097 13.038 13.884 11.4255C13.9125 10.7925 13.9185 10.6027 13.9185 9.00075C13.9185 7.39875 13.9125 7.209 13.884 6.57675C13.8097 4.962 13.0507 4.19025 11.4247 4.116ZM9 12.081C7.29825 12.081 5.919 10.7017 5.919 9C5.919 7.29825 7.299 5.919 9 5.919C10.701 5.919 12.081 7.29825 12.081 9C12.081 10.7017 10.7017 12.081 9 12.081ZM12.2032 6.5175C11.8057 6.5175 11.4832 6.195 11.4832 5.7975C11.4832 5.4 11.8057 5.0775 12.2032 5.0775C12.6007 5.0775 12.9233 5.4 12.9233 5.7975C12.9233 6.195 12.6007 6.5175 12.2032 6.5175ZM11.0002 9C11.0002 10.1047 10.1047 11.0002 9 11.0002C7.89525 11.0002 6.99975 10.1047 6.99975 9C6.99975 7.89525 7.89525 6.99975 9 6.99975C10.1047 6.99975 11.0002 7.89525 11.0002 9ZM14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0V0ZM14.964 11.4735C14.865 13.6553 13.6508 14.8643 11.4742 14.964C10.8337 14.9932 10.6298 15 9 15C7.37025 15 7.167 14.9932 6.5265 14.964C4.3455 14.8643 3.1365 13.653 3.036 11.4735C3.00675 10.8338 3 10.6298 3 9C3 7.371 3.00675 7.16625 3.036 6.5265C3.1365 4.3455 4.347 3.13575 6.5265 3.03675C7.167 3.00675 7.37025 3 9 3C10.6298 3 10.8337 3.00675 11.4742 3.036C13.6552 3.13575 14.8665 4.34925 14.964 6.52575C14.9932 7.16625 15 7.371 15 9C15 10.6298 14.9932 10.8338 14.964 11.4735Z"
      fill="#2B2B2B"
    />
  </svg>
);

export default memo(InstagramBlackIcon);
