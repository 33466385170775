import React, { memo } from 'react';

interface ScheduleIconProps {
  className?: string;
  color?: string;
}
const ScheduleIcon: React.FC<ScheduleIconProps> = ({ className, color }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92285 0.5C4.65763 0.5 4.40328 0.605357 4.21574 0.792893C4.02821 0.98043 3.92285 1.23478 3.92285 1.5V2.5H2.92285C2.39242 2.5 1.88371 2.71071 1.50864 3.08579C1.13357 3.46086 0.922852 3.96957 0.922852 4.5V14.5C0.922852 15.0304 1.13357 15.5391 1.50864 15.9142C1.88371 16.2893 2.39242 16.5 2.92285 16.5H14.9229C15.4533 16.5 15.962 16.2893 16.3371 15.9142C16.7121 15.5391 16.9229 15.0304 16.9229 14.5V4.5C16.9229 3.96957 16.7121 3.46086 16.3371 3.08579C15.962 2.71071 15.4533 2.5 14.9229 2.5H13.9229V1.5C13.9229 1.23478 13.8175 0.98043 13.63 0.792893C13.4424 0.605357 13.1881 0.5 12.9229 0.5C12.6576 0.5 12.4033 0.605357 12.2157 0.792893C12.0282 0.98043 11.9229 1.23478 11.9229 1.5V2.5H5.92285V1.5C5.92285 1.23478 5.81749 0.98043 5.62996 0.792893C5.44242 0.605357 5.18807 0.5 4.92285 0.5ZM4.92285 5.5C4.65763 5.5 4.40328 5.60536 4.21574 5.79289C4.02821 5.98043 3.92285 6.23478 3.92285 6.5C3.92285 6.76522 4.02821 7.01957 4.21574 7.20711C4.40328 7.39464 4.65763 7.5 4.92285 7.5H12.9229C13.1881 7.5 13.4424 7.39464 13.63 7.20711C13.8175 7.01957 13.9229 6.76522 13.9229 6.5C13.9229 6.23478 13.8175 5.98043 13.63 5.79289C13.4424 5.60536 13.1881 5.5 12.9229 5.5H4.92285Z"
      fill="CurrentColor"
    />
  </svg>
);

export default memo(ScheduleIcon);
