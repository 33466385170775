import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-ca';
import 'moment/locale/es';
import 'moment/locale/zh-cn';
import 'moment-timezone';

import { defaultTimezone } from '_constants/otherConstants';

export const changeMomentLang = (lang) => {
  switch (lang) {
    case 'en':
      moment.locale('en-ca');
      break;
    case 'zh':
      moment.locale('zh-cn');
      break;
    default:
      moment.locale(lang);
      break;
  }
};

export const getCurrentTimeZone = () => moment.tz?.guess() || defaultTimezone;
