import React, { ReactNode, memo, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { HeaderTypes } from '_constants/headerConstants';

import Header from 'app/components/Header';
import GeneralFooter from 'app/components/Footers/GeneralFooter';

import clsx from 'clsx';

interface GeneralPageWrapperProps {
  withOutlet: boolean;
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
}

const GeneralPageWrapper: React.FC<GeneralPageWrapperProps> = ({
  title,
  withOutlet,
  children,
  className,
}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content="Nextstreet advisory platform homepage"
          />
        </Helmet>
      )}

      <Header type={HeaderTypes.general} />

      <main className={clsx('app_wrapper', className)}>
        {children}
        {withOutlet && <Outlet />}
      </main>

      <GeneralFooter />
    </>
  );
};

export default memo(GeneralPageWrapper);
