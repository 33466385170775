import React, { useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import './style.scss';

interface InfoModalProps {
  onClose: Function;
  title?: string;
  message?: JSX.Element;
  classNames?: string;
  titleIcon?: JSX.Element;
  footer: JSX.Element;
  closeOnBackgroundClick?: boolean;
}

const InfoModal: React.FC<InfoModalProps> = ({
  onClose,
  title,
  message,
  titleIcon,
  classNames,
  footer,
  closeOnBackgroundClick = false,
}) => {
  // TODO: Migrate to using the InfoModalNew completely and delete the InfoModal component
  const { t } = useTenantTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const modalInnerContent = useMemo(
    () => (
      <>
        <div className="content">
          {titleIcon}
          {title && <h1 className="title">{t(title)}</h1>}
          <div className="info_modal_block__body"> {message}</div>
        </div>
        <div className="info_modal_block__footer">{footer}</div>
      </>
    ),
    [titleIcon, title, message, footer],
  );

  const { ref } = useOnClickOutside(closeOnBackgroundClick, () => onClose);

  return (
    <div className="info_modal_wrapper">
      {closeOnBackgroundClick ? (
        <div className={clsx('info_modal_block', classNames)} ref={ref}>
          {modalInnerContent}
        </div>
      ) : (
        <div className={clsx('info_modal_block', classNames)}>
          {modalInnerContent}
        </div>
      )}
    </div>
  );
};

export default memo(InfoModal);
