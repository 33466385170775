import isEmpty from 'lodash/isEmpty';
import { TFunction } from 'i18next';

import { getShouldShowNYCBorough } from '_constants/onboardingConstants';

import { ProfileInfoType } from '_types/profile.interface';
import { UserRolesEnum } from '_types/user.interface';

import { transformValuesProfileValidationSchema } from 'app/pages/MyAccount/utils/transformValuesProfileValidationSchema';

import { actionChangeProfileMissingDataValidationErrors } from 'store/reducers/profile';

import {
  constructErrorsObjectFromYupException,
  YupExceptionType,
} from 'utils/validationSchemas';

type ValidatorType = (value: unknown, profile?: ProfileInfoType) => boolean;

interface MissingDataValidationSchema {
  [key: string]: {
    validateIf?: ValidatorType[];
    validators: ValidatorType[];
  };
}

const not =
  (func: (...rest: any[]) => boolean) =>
  (...args: any[]) =>
    !func(...args);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const and =
  (...funcs: ((...rest: any[]) => boolean)[]) =>
  (...args: any[]) =>
    funcs.every((func) => func(...args));

// eslint-disable-next-line max-len
const isPagesActive = (paths: string[]) =>
  paths.some((path) => window.location.pathname.includes(path));
const isUndefined: ValidatorType = (value) => !value;
const isArrayEmpty: ValidatorType = (value) => (value as any[]).length === 0;
const isAdvisor: ValidatorType = (_, profile) => !!profile?.isAdvisor;
const doesNYCBoroughRequired: ValidatorType = (_, profile) =>
  getShouldShowNYCBorough(profile?.sboData);

const advisoryOrgValidationSchema: MissingDataValidationSchema = {
  'advisorData.industries': {
    validateIf: [isAdvisor],
    validators: [isUndefined, isArrayEmpty],
  },
};

const advisorValidationSchema: MissingDataValidationSchema = {
  'advisorData.industries': {
    validators: [isUndefined, isArrayEmpty],
  },
};

const sboValidationSchema: MissingDataValidationSchema = {
  'sboData.industry': {
    validators: [isUndefined],
  },
  'sboData.NYCBorough': {
    validators: [
      and(isUndefined, not(isPagesActive.bind(null, ['/my-account/profile']))),
    ],
    validateIf: [doesNYCBoroughRequired],
  },
};

const getObjectField = (obj: object, key: string[]) => {
  if (key.length === 1) {
    return obj[key[0]];
  }
  return getObjectField(obj[key[0]], key.slice(1));
};

const validateProfile = (
  schema: MissingDataValidationSchema,
  profile: ProfileInfoType,
) => {
  const fields = Object.entries(schema);
  const result: string[] = [];
  fields.forEach((field) => {
    const key = field[0];
    const value = getObjectField(profile, key.split('.'));
    const { validateIf } = field[1];
    const { validators } = field[1];

    let validateIfResult = true;

    if (validateIf) {
      validateIfResult = validateIf.every((validator) =>
        validator(value, profile),
      );
    }

    if (validateIfResult) {
      const isValid = validators.every(
        (validator) => !validator(value, profile),
      );
      if (!isValid) {
        result.push(key);
      }
    }
  });

  return result;
};

export const getProfileMissingData = (
  profile: ProfileInfoType | null,
): string[] => {
  const role = profile?.role;

  if (role === UserRolesEnum.ADVISORY_ORG_ADMIN && profile?.advisorData) {
    return validateProfile(advisoryOrgValidationSchema, profile);
  }

  if (role === UserRolesEnum.ADVISOR && profile?.advisorData) {
    return validateProfile(advisorValidationSchema, profile);
  }

  if (role === UserRolesEnum.SBO && profile?.sboData) {
    return validateProfile(sboValidationSchema, profile);
  }

  return [];
};

export const validateProfileMissingData = async (
  dispatch: Function,
  t: TFunction,
  dataValidationSchema: any,
  profileInfo: ProfileInfoType,
): Promise<ProfileInfoType | null> => {
  let dataToSave: ProfileInfoType | null = null;

  if (dataValidationSchema) {
    try {
      const schemaToUse = dataValidationSchema.concat(
        transformValuesProfileValidationSchema,
      );

      dataToSave = await schemaToUse.validate(profileInfo, {
        abortEarly: false,
      });

      dispatch(actionChangeProfileMissingDataValidationErrors({}));
    } catch (e: any) {
      const newErrors = constructErrorsObjectFromYupException(
        t,
        e as YupExceptionType,
      );

      if (isEmpty(newErrors)) {
        // eslint-disable-next-line no-console
        console.error(e);
      } else {
        dispatch(actionChangeProfileMissingDataValidationErrors(newErrors));
      }

      return null;
    }
  }

  return dataToSave;
};
