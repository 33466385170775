import { useEffect } from 'react';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'store/selectors/profile';
import { tenantSettingsTenantFeatureFlagsSelector } from 'store/selectors/tenant-settings';

export const useGATagAddUserId = (
  currentAccessToken: string | null,
  isProfileInfoPresent: boolean,
) => {
  const profileId = useAppSelector(profileIdSelector);
  const tenantFeatureFlags = useAppSelector(
    tenantSettingsTenantFeatureFlagsSelector,
  );

  useEffect(() => {
    if (
      !tenantFeatureFlags?.users ||
      !isProfileInfoPresent ||
      !currentAccessToken
    ) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: profileId,
      event: 'userIdSet',
    });
  }, [
    isProfileInfoPresent,
    currentAccessToken,
    tenantFeatureFlags?.users,
    profileId,
  ]);
};
