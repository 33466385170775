import React, { memo } from 'react';

interface GlobalErrorPageImageProps {
  className?: string;
  color1?: string;
  color2?: string;
  color3?: string;
}

const GlobalErrorPageImage: React.FC<GlobalErrorPageImageProps> = ({
  className = '',
  color1 = '#053B66',
  color2 = '#457CA8',
  color3 = '#D2EBFF',
}) => (
  <svg
    className={className}
    width="560"
    height="560"
    viewBox="0 0 560 560"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.6" cx="276.5" cy="281.5" r="175.5" fill={color3} />
    <circle opacity="0.6" cx="112" cy="163" r="11" fill={color3} />
    <circle opacity="0.6" cx="396.5" cy="119.5" r="20.5" fill={color3} />
    <circle opacity="0.6" cx="93.5" cy="394.5" r="19.5" fill={color3} />
    <circle opacity="0.6" cx="464.5" cy="375.5" r="19.5" fill={color3} />
    <circle opacity="0.6" cx="431.5" cy="404.5" r="9.5" fill={color3} />
    <path
      d="M305.791 133.537C305.211 133.414 305.211 132.586 305.791 132.463L314.557 130.599C314.768 130.554 314.933 130.389 314.979 130.178L316.866 121.474C316.991 120.897 317.814 120.897 317.939 121.474L319.827 130.178C319.873 130.389 320.038 130.554 320.249 130.599L329.015 132.463C329.595 132.586 329.595 133.414 329.015 133.537L320.249 135.401C320.038 135.446 319.873 135.611 319.827 135.822L317.939 144.526C317.814 145.103 316.991 145.103 316.866 144.526L314.979 135.822C314.933 135.611 314.768 135.446 314.557 135.401L305.791 133.537Z"
      fill={color1}
    />
    <path
      d="M92.2229 288.622C92.4467 287.565 93.9558 287.565 94.1796 288.622L97.5224 304.415C97.6038 304.799 97.9034 305.101 98.2878 305.185L113.917 308.589C114.967 308.818 114.967 310.315 113.917 310.544L98.2878 313.948C97.9034 314.032 97.6038 314.334 97.5224 314.718L94.1796 330.511C93.9558 331.568 92.4467 331.568 92.2229 330.511L88.8801 314.718C88.7986 314.334 88.499 314.032 88.1146 313.948L72.4851 310.544C71.4356 310.315 71.4356 308.818 72.4851 308.589L88.1146 305.185C88.499 305.101 88.7986 304.799 88.8801 304.415L92.2229 288.622Z"
      fill={color1}
    />
    <path
      d="M433.345 167.622C433.568 166.565 435.078 166.565 435.301 167.622L437.251 176.835C437.333 177.22 437.633 177.521 438.017 177.605L447.161 179.597C448.21 179.826 448.21 181.323 447.161 181.551L438.017 183.543C437.633 183.627 437.333 183.928 437.251 184.313L435.301 193.526C435.078 194.583 433.568 194.583 433.345 193.526L431.395 184.313C431.313 183.928 431.014 183.627 430.629 183.543L421.485 181.551C420.436 181.323 420.436 179.826 421.485 179.597L430.629 177.605C431.014 177.521 431.313 177.22 431.395 176.835L433.345 167.622Z"
      fill={color1}
    />
    <path
      d="M473.659 315.331L476.721 329.798L490.982 332.905L476.721 336.011L473.659 350.479L470.597 336.011L456.336 332.905L470.597 329.798L473.659 315.331Z"
      fill="white"
    />
    <path
      d="M197.539 209.873L197.59 209.899L197.643 209.921C203.601 212.514 207.89 214.759 210.845 216.735C213.849 218.743 215.24 220.323 215.755 221.491C216.176 222.444 216.081 223.265 215.369 224.293C214.566 225.452 213.056 226.715 210.838 228.151C208.71 229.528 206.135 230.94 203.284 232.503L203.047 232.634C200.129 234.234 196.963 235.976 193.88 237.926C187.75 241.803 181.687 246.655 178.365 253.232C175.016 259.864 175.07 267.54 176.048 274.638C176.54 278.209 177.278 281.711 177.984 284.936C178.074 285.35 178.164 285.759 178.253 286.162C178.862 288.932 179.415 291.446 179.76 293.675C180.161 296.269 180.23 298.194 179.914 299.507C179.765 300.129 179.548 300.528 179.311 300.792C179.087 301.043 178.759 301.269 178.213 301.417C177.017 301.743 174.911 301.658 171.383 300.599C167.945 299.566 163.42 297.691 157.523 294.775C141.495 283.551 130.425 272.105 124.823 260.651C119.258 249.271 119.043 237.809 124.891 226.229C136.527 203.185 168.877 195.399 197.539 209.873Z"
      fill={color2}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M58.7335 202.038L59.9291 201.939C83.8362 199.961 107.763 205.507 128.362 217.801V217.801"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M51.4604 215.687L53.4542 215.516C77.0847 213.483 100.764 218.829 121.23 230.815V230.815"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M242.649 285.693C235.788 299.28 228.032 310.293 217.937 315.839C208.021 321.286 195.418 321.674 178.243 313.001C156.226 301.883 148.547 271.57 161.984 244.96C175.412 218.369 204.334 206.551 226.345 217.63C244.114 227.766 251.256 238.155 252.63 248.977C254.03 260.007 249.542 272.043 242.649 285.693Z"
      fill={color2}
      stroke={color1}
      strokeWidth="4"
    />
    <ellipse
      cx="212.338"
      cy="270.252"
      rx="38.238"
      ry="52.6208"
      transform="rotate(-155.936 212.338 270.252)"
      fill="white"
    />
    <path
      d="M247.288 288.035C240.566 301.348 230.508 311.308 219.766 316.693C209.024 322.078 197.733 322.842 188.292 318.074C178.851 313.307 172.763 303.767 170.72 291.926C168.677 280.085 170.72 266.079 177.443 252.766C184.166 239.452 194.224 229.493 204.966 224.108C215.708 218.723 226.999 217.959 236.439 222.726C245.88 227.494 251.968 237.033 254.012 248.875C256.055 260.716 254.011 274.722 247.288 288.035Z"
      fill="white"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M218.095 254.376C220.155 250.298 224.664 248.781 228.01 250.47C231.356 252.16 232.813 256.69 230.753 260.768C228.694 264.847 224.184 266.364 220.838 264.674C217.492 262.985 216.035 258.455 218.095 254.376Z"
      fill={color1}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M203.265 283.738C205.324 279.66 209.834 278.143 213.18 279.832C216.526 281.522 217.983 286.052 215.923 290.13C213.864 294.209 209.354 295.726 206.008 294.036C202.662 292.346 201.205 287.817 203.265 283.738Z"
      fill={color1}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M306.74 273.584L306.806 273.552L306.869 273.514C343.68 251.588 387.668 258.855 405.438 288.689C423.208 318.523 408.649 360.663 371.838 382.59L371.788 382.619L371.739 382.652C366.506 386.197 362.371 388.442 359.104 389.657C355.818 390.879 353.621 390.98 352.131 390.542C350.723 390.129 349.619 389.138 348.706 387.351C347.766 385.511 347.11 382.976 346.612 379.786C346.118 376.614 345.802 372.967 345.459 368.956L345.441 368.743C345.106 364.817 344.744 360.577 344.16 356.254C342.974 347.464 340.852 338.145 336.022 330.036C331.195 321.931 324.02 315.574 316.863 310.277C313.354 307.68 309.813 305.313 306.532 303.119L306.322 302.978C302.962 300.731 299.906 298.682 297.353 296.708C294.786 294.723 292.866 292.918 291.694 291.204C290.552 289.534 290.211 288.095 290.514 286.686C290.835 285.197 291.957 283.354 294.588 281.108C297.205 278.874 301.139 276.389 306.74 273.584Z"
      fill={color2}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M479.05 232.143L478.31 233.552C463.514 261.735 440.277 284.587 411.851 298.91V298.91"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M468.937 214.28L467.71 216.635C453.172 244.547 430.337 267.265 402.35 281.658V281.658"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M254.017 296.922L264.873 290.455L273.756 285.165"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M269.228 286.566L269.314 286.525L269.395 286.477C297.904 269.496 337.691 282.703 358.065 316.908C378.433 351.104 371.107 392.366 342.617 409.355C318.903 422.613 301.964 423.298 288.52 416.893C274.872 410.39 264.308 396.371 253.924 378.938C243.593 361.592 236.42 343.654 237.292 327.539C237.725 319.531 240.145 311.961 245.185 305.082C250.235 298.19 257.987 291.893 269.228 286.566Z"
      fill={color2}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M248.003 382.466C237.812 365.355 233.775 346.864 235.402 330.849C237.03 314.833 244.29 301.431 256.565 294.12C268.841 286.808 284.083 286.807 298.941 293.003C313.798 299.199 328.135 311.555 338.327 328.666C348.518 345.777 352.555 364.268 350.928 380.283C349.3 396.299 342.04 409.7 329.765 417.012C317.489 424.324 302.247 424.325 287.389 418.129C272.532 411.933 258.195 399.576 248.003 382.466Z"
      fill="white"
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M309.717 367.471C312.229 371.69 310.846 377.146 306.628 379.659L247.075 415.131C242.857 417.643 237.4 416.26 234.888 412.042C232.375 407.824 233.758 402.367 237.976 399.855L297.529 364.383C301.748 361.87 307.204 363.253 309.717 367.471Z"
      fill={color3}
      stroke={color1}
      strokeWidth="4"
    />
    <path
      d="M284.719 329.471C287.231 333.69 285.848 339.146 281.63 341.659L222.077 377.131C217.859 379.643 212.402 378.26 209.89 374.042C207.377 369.824 208.76 364.367 212.978 361.855L272.531 326.383C276.75 323.87 282.206 325.253 284.719 329.471Z"
      fill={color3}
      stroke={color1}
      strokeWidth="4"
    />
  </svg>
);

export default memo(GlobalErrorPageImage);
