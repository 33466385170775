import React, { memo } from 'react';

import useTenantColors from 'utils/hooks/useTenantColors';

interface LogoIconPlainProps {
  className?: string;
}
const LogoIconPlain: React.FC<LogoIconPlainProps> = ({ className }) => {
  const { colorVariables } = useTenantColors();

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="16"
      viewBox="0 0 120 16"
      fill="none"
    >
      <path
        d="M106.999 11.8328C106.469 12.5575 105.87 13.0825 104.468 13.0825C102.993 13.0825 101.676 12.1505 101.586 10.4636H110.729V9.80687C110.729 5.75719 108.209 3.03802 104.461 3.03802C100.77 3.03802 97.9874 5.81746 97.9874 9.50395C97.9874 13.3272 100.791 15.9851 104.466 15.9999C108.113 16.0154 109.841 13.9367 110.62 11.8308L106.999 11.8328ZM104.457 5.83297C106.2 5.83297 107.275 6.88034 107.419 8.06852H101.55C101.707 6.92954 102.871 5.83297 104.457 5.83297Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M93.2278 11.8328C92.6991 12.5575 92.1009 13.0825 90.6967 13.0825C89.2216 13.0825 87.9048 12.1505 87.8156 10.4636H96.9577V9.80687C96.9577 5.75719 94.4391 3.03802 90.689 3.03802C87.0001 3.03802 84.2177 5.81746 84.2177 9.50395C84.2177 13.3272 87.0216 15.9851 90.6961 15.9999C94.3439 16.0154 96.0712 13.9367 96.8504 11.8308L93.2278 11.8328ZM90.686 5.83297C92.4274 5.83297 93.5052 6.88034 93.6475 8.06852H87.78C87.9365 6.92954 89.1014 5.83297 90.686 5.83297Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M22.7396 11.8328C22.2082 12.5575 21.6114 13.0825 20.2075 13.0825C18.7347 13.0825 17.4143 12.1505 17.3261 10.4636H26.4689V9.80687C26.4689 5.75719 23.9503 3.03802 20.2001 3.03802C16.5099 3.03802 13.7288 5.81746 13.7288 9.50395C13.7288 13.3272 16.5307 15.9851 20.2055 15.9999C23.8537 16.0154 25.582 13.9367 26.3588 11.8308L22.7396 11.8328ZM20.1974 5.83297C21.9385 5.83297 23.016 6.88034 23.1577 8.06852H17.2908C17.448 6.92954 18.6101 5.83297 20.1974 5.83297Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M3.85588 4.76157V3.32839H0.435547V15.6822H3.85588V7.4057C4.19886 6.98201 5.11708 6.04999 6.57513 6.04999C8.06445 6.04999 8.75915 6.74339 8.75915 8.23166V15.6822H12.2061V6.99615C12.2061 4.43611 10.7413 3.02515 8.08125 3.02515C6.18667 3.02515 4.69129 3.89489 3.85588 4.76157Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M45.8039 0H42.3836V3.32841H40.3402V6.30146H42.3836V12.5436C42.3836 14.794 43.6162 15.9841 45.945 15.9841C47.4794 15.9841 48.2963 15.5476 48.7116 15.1817L48.753 15.1447L48.0038 12.4898L47.8961 12.6116C47.7466 12.7802 47.3252 12.9593 46.8399 12.9593C46.2098 12.9593 45.8039 12.4598 45.8039 11.686V6.30146H48.307V3.32841H45.8039V0Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M55.3978 6.94598C55.3978 9.93043 58.2229 10.4905 60.2869 10.9014C61.6342 11.1697 62.7025 11.3807 62.7025 12.0888C62.7025 12.8512 61.9796 13.2878 60.7221 13.2878C59.1523 13.2878 57.3853 12.4292 56.5186 11.6217L56.4377 11.5473L54.9397 14.0225L54.9935 14.0699C56.3102 15.2689 58.3946 15.9841 60.5696 15.9841C63.8805 15.9841 66.0202 14.4254 66.0202 12.0142C66.0202 8.87627 63.0916 8.29499 60.9537 7.87025C59.6908 7.61943 58.6922 7.42153 58.6922 6.81973C58.6922 6.1739 59.4532 5.72219 60.5444 5.72219C62.0846 5.72219 63.4555 6.41785 64.1316 7.10754L64.2141 7.19131L65.6122 4.75518L65.552 4.70841C64.1431 3.6078 62.4124 3.02515 60.5444 3.02515C57.0086 3.02515 55.3978 5.05705 55.3978 6.94598Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M71.9515 0H68.5302V3.32841H66.4882V6.30146H68.5302V12.5436C68.5302 14.794 69.7624 15.9841 72.0929 15.9841C73.6263 15.9841 74.4439 15.5476 74.8588 15.1817L74.8992 15.1447L74.1514 12.4898L74.0436 12.6116C73.8932 12.7802 73.4725 12.9593 72.9871 12.9593C72.3578 12.9593 71.9515 12.4598 71.9515 11.686V6.30146H74.4543V3.32841H71.9515V0Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M83.7629 3.02515C82.4083 3.02515 80.931 3.74343 79.918 4.84945V3.32839H76.4983V15.6822H79.918V7.58478C80.4207 6.87328 81.7886 6.25266 82.8683 6.25266C83.2352 6.25266 83.5146 6.27584 83.7438 6.32568L83.8495 6.34887V3.02515H83.7629Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M116.615 0H113.197V3.32841H111.154V6.30146H113.197V12.5436C113.197 14.794 114.428 15.9841 116.758 15.9841C118.291 15.9841 119.109 15.5476 119.523 15.1817L119.565 15.1447L118.815 12.4898L118.708 12.6116C118.558 12.7802 118.139 12.9593 117.651 12.9593C117.023 12.9593 116.615 12.4598 116.615 11.686V6.30146H119.12V3.32841H116.615V0Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M30.3813 3.2948H26.3107L30.1935 8.44541L32.2288 5.74781L30.3813 3.2948Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M33.7709 13.1787L35.6416 15.663H39.7125L35.8062 10.4807L33.7709 13.1787Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
      <path
        d="M39.7125 3.2948H35.6417L33.0116 6.78104L30.9764 9.47873L26.3107 15.663H30.3813L33.0116 12.1767L35.0469 9.47873L39.7125 3.2948Z"
        fill={colorVariables?.text.text_secondary || '#4C5C68'}
      />
    </svg>
  );
};

export default memo(LogoIconPlain);
