import { TenantStyling } from '_types/tenant-settings/tenant-styling.interface';

export const changeFontSettings = (tenantStyling: TenantStyling) => {
  document.documentElement.style.setProperty(
    '--app-font-family-1',
    tenantStyling.font1,
  );
  document.documentElement.style.setProperty(
    '--app-font-family-2',
    tenantStyling.font2,
  );
};
