import type { UserRolesEnum } from './user.interface';

export interface SboSmallData {
  id: number;
  userId: number;
  userRole: UserRolesEnum;
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
}

export enum SboType {
  PRE_STARTUP = 'PRE_STARTUP',
  STARTUP = 'STARTUP',
  OPERATING = 'OPERATING',
}

export interface SboAsClientSection {
  [key: string]: string | SboAsClientSection;
}

export interface SboAsClient {
  [sectionName: string]: SboAsClientSection;
}
