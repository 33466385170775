/* eslint-disable import/no-import-module-exports */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import App from 'app';
import GlobalErrorBoundary from 'app/components/GlobalErrorBoundary';
import Loader from 'app/components/Loader';

import { store } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages and new relic
import FirebaseRemoteConfigInitialization from './utils/firebase/FirebaseRemoteConfigInitialization';
import './utils/newRelic';
import './locales/i18n';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />),
);

createRoot(MOUNT_NODE!).render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <HelmetProvider>
        <CookiesProvider>
          {/* <React.StrictMode> */}
          <GlobalErrorBoundary>
            <FirebaseRemoteConfigInitialization>
              <RouterProvider router={router} />
            </FirebaseRemoteConfigInitialization>
          </GlobalErrorBoundary>
          {/* </React.StrictMode> */}
        </CookiesProvider>
      </HelmetProvider>
    </Provider>
  </Suspense>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
