import React, { memo } from 'react';

interface FundingIconProps {
  className?: string;
  color?: string;
}
const FundingIcon: React.FC<FundingIconProps> = ({ className, color }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 19C15.4706 19 19.5 14.9706 19.5 10C19.5 5.02944 15.4706 1 10.5 1C5.52944 1 1.5 5.02944 1.5 10C1.5 14.9706 5.52944 19 10.5 19ZM6.61111 12.1667H12.7222C12.8696 12.1667 13.0109 12.1096 13.1151 12.008C13.2192 11.9064 13.2778 11.7687 13.2778 11.625C13.2778 11.4813 13.2192 11.3436 13.1151 11.242C13.0109 11.1404 12.8696 11.0833 12.7222 11.0833H8.27778C7.54107 11.0833 6.83453 10.798 6.31359 10.2901C5.79266 9.78217 5.5 9.09329 5.5 8.375C5.5 7.65671 5.79266 6.96783 6.31359 6.45992C6.83453 5.95201 7.54107 5.66667 8.27778 5.66667H9.38889V3.5H11.6111V5.66667H14.3889V7.83333H8.27778C8.13044 7.83333 7.98913 7.8904 7.88494 7.99198C7.78075 8.09357 7.72222 8.23134 7.72222 8.375C7.72222 8.51866 7.78075 8.65643 7.88494 8.75802C7.98913 8.8596 8.13044 8.91667 8.27778 8.91667H12.7222C13.4589 8.91667 14.1655 9.20201 14.6864 9.70992C15.2073 10.2178 15.5 10.9067 15.5 11.625C15.5 12.3433 15.2073 13.0322 14.6864 13.5401C14.1655 14.048 13.4589 14.3333 12.7222 14.3333H11.6111V16.5H9.38889V14.3333H6.61111V12.1667Z"
      fill={color || '#D2EBFF'}
    />
  </svg>
);

export default memo(FundingIcon);
