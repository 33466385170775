import React, { memo, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showMenuModalSelector } from 'store/selectors/modal';
import { actionHandleShowMenuModal } from 'store/reducers/modal';

import MenuIcon from '../Icons/MenuIcon';

const ModulesHamburgerMenuForMobile: React.FC = memo(() => {
  const dispatch = useAppDispatch();

  const isModalShown = useAppSelector(showMenuModalSelector);
  const handleMenuButton = useCallback(() => {
    dispatch(actionHandleShowMenuModal(!isModalShown));
  }, []);

  return (
    <button
      type="button"
      onClick={handleMenuButton}
      className="profile_menu_button"
    >
      <MenuIcon />
    </button>
  );
});

export default ModulesHamburgerMenuForMobile;
