import React, { ReactElement } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Trans } from 'react-i18next';

import { UserRolesEnum } from '_types/user.interface';

import ScheduleIcon from 'app/components/Icons/ScheduleIcon';

import { NotificationUserMentioned } from '../../types';
import { LinkToObjectType } from '../NotificationBlock/types';

import {
  SessionDataNotificationSessionObject,
  SessionDataNotificationTypeEnum,
} from './types';

export const getTitleBlock = (
  sessionNotificationType: SessionDataNotificationTypeEnum,
  userMentioned: NotificationUserMentioned,
  t: TFunction,
): ReactElement | null => {
  switch (sessionNotificationType) {
    case SessionDataNotificationTypeEnum.BOOKED:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.booked_a_session_with_you', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    case SessionDataNotificationTypeEnum.SCHEDULED:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.scheduled_a_session_with_you', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    case SessionDataNotificationTypeEnum.RESCHEDULED:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.rescheduled_a_session_with_you', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    case SessionDataNotificationTypeEnum.CANCELED_TO_SBO:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.cancelled_a_session_with_you', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    case SessionDataNotificationTypeEnum.CANCELED_TO_ADVISOR:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.your_session_been_cancelled', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    default:
      return null;
  }
};

const getSessionMethodTranslationKey = (
  session: SessionDataNotificationSessionObject,
) => {
  if (session.zoomUrl) {
    return 'misc.video_call_zoom';
  }

  if (session.phoneNumber) {
    return 'misc.phone_call';
  }

  return 'misc.unknown';
};

export const getContentBlock = (
  session: SessionDataNotificationSessionObject,
  t: TFunction,
): ReactElement | null => {
  const dateAt = moment.unix(session.startTime).format('dddd, MMM D');
  const timeStart = moment.unix(session.startTime).format('h:mm A');
  const timeEnd = moment.unix(session.endTime).format('h:mm A');

  const sessionMethodTranslationKey = getSessionMethodTranslationKey(session);

  return (
    <div className="scheduled">
      <div className="scheduled_item">
        <span className="scheduled_item__name">{t('misc.name')}</span>
        <span className="scheduled_item__content">{session.sessionName}</span>
      </div>
      <div className="scheduled_item">
        <span className="scheduled_item__name">{t('misc.method')}</span>
        <span className="scheduled_item__content">
          {t(sessionMethodTranslationKey)}
        </span>
      </div>
      <div className="scheduled_item">
        <span className="scheduled_item__name">{t('misc.when')}</span>
        <span className="scheduled_item__content">
          {t('notifications.date_at', { dateAt, timeStart, timeEnd })}
        </span>
      </div>
      <div className="scheduled_item">
        <span className="scheduled_item__name">
          {t('misc.profile_sharing')}
        </span>
        <span className="scheduled_item__content">
          {session.profileSharing}
        </span>
      </div>
    </div>
  );
};

export const getLinkToObject = (
  session: SessionDataNotificationSessionObject,
  currentUserRole: UserRolesEnum,
  t: TFunction,
): LinkToObjectType | null => ({
  startIcon: <ScheduleIcon />,
  parts: [
    {
      content: <>{t('misc.session_upper')}</>,
      link:
        currentUserRole === UserRolesEnum.SBO ? '/advisors/list' : '/clients',
    },
    {
      content: session.sessionName,
      link: `/advisors/session/${session.sessionId}`,
    },
  ],
});
