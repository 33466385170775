import React, { memo, useMemo } from 'react';

import { useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'store/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import NotificationBlock from '../NotificationBlock';

import { getContentBlock, getLinkToObject, getTitleBlock } from './utils';
import { SessionCompletedNotificationNotificationObject } from './types';

interface SessionCompletedNotificationProps {
  notification: SessionCompletedNotificationNotificationObject;
}

const SessionCompletedNotification: React.FC<
  SessionCompletedNotificationProps
> = ({ notification }) => {
  const { t } = useTenantTranslation();

  const profileRole = useAppSelector(profileRoleSelector);

  const titleBlock = useMemo(
    () => getTitleBlock(notification.data.userMentioned, t),
    [notification.data.userMentioned, t],
  );

  const contentBlock = useMemo(
    () => getContentBlock(notification.data.session),
    [notification.data.session],
  );

  const linkToObject = useMemo(
    () => getLinkToObject(notification.data.session, profileRole!, t),
    [notification.data.session, profileRole, t],
  );

  return (
    <NotificationBlock
      notificationId={notification.id}
      isRead={notification.isRead}
      createdAtUnix={notification.createdAtUnix}
      logoUser={notification.data.userMentioned}
      titleBlock={titleBlock}
      contentBlock={contentBlock}
      linkToObject={linkToObject}
    />
  );
};

export default memo(SessionCompletedNotification);
